import { Box, Divider, Grid, List, ListItem, ListItemText, Paper, Stack, Typography, Skeleton, Pagination, Select, MenuItem } from "@mui/material";
import classes from "./SubscriptionTab3.module.css";
import BaseFillTextField from "../ui/BaseFillTextField";
import LightTooltip from "../ui/LightTooltip";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useState, useEffect } from "react";
import FiltersButton from "./FiltersButton";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import plansNew from "../Js/plans"

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        credits: <Skeleton variant="text" animation="wave" width="100%" height={20} />,
    });
}

const itemsPerPageOptions = [25, 50, 100];

const CreditsRecord = (props) => {
    const {
        franchise_id,
        company_id,
        credits,
        creditsOG,
        setCredits,
        setCreditsOG,
        showSkeleton,
        setShowSkeleton,
        usersFranq,
    } = props;

    const todos = useSelector((state) => state.value);

    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));

    // Función para agrupar por fecha
    const groupByDate = (arr) =>
        arr.reduce((acc, obj) => {
            const date = obj.creation_date.split("T")[0]; // Extraer la fecha sin la hora
            acc[date] = acc[date] || [];
            acc[date].push(obj);
            return acc;
        }, {});

    const [t, i18n] = useTranslation("global");

    const [search, setSearch] = useState("");

    // Estados de paginado.
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(25);
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const totalPages = Math.ceil(credits.length / itemsPerPage);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (event) => {
        const newItemsPerPage = parseFloat(event.target.value);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const handleChangeSearch = (event) => {
        let newArr = creditsOG.filter(
            (item) =>
                moment(item.creation_date).format("MMMM").toLowerCase().includes(event.target.value.toLowerCase()) ||
                moment(item.creation_date).format("YYYY").includes(event.target.value) ||
                moment(item.creation_date).format("D").includes(event.target.value) ||
                item.quantity.toString().includes(event.target.value) ||
                item.roll_over_date.toString().includes(event.target.value) ||
                item.user_id?.user_name.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setSearch(event.target.value);
        setCredits(newArr);
    };

    const plansInfo = [
        {
            planId: 1,
            conversions: 5,
        },
        {
            planId: 2,
            conversions: 15,
        },
        {
            planId: 3,
            conversions: 30,
        },
        {
            planId: 4,
            conversions: 50,
        },
        {
            planId: 5,
            conversions: 200,
        },
        {
            planId: 6,
            conversions: 500,
        },
    ];

    const zeroPad = (num, places) => String(num).padStart(places, "0");

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    useEffect(() => {
        if (currentPage) {
            const firstIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = Math.min(firstIndex + itemsPerPage, credits.length);

            setStartIndex(firstIndex);
            setEndIndex(endIndex);
        }
    }, [credits, currentPage, itemsPerPage, totalPages]);

    useEffect(() => {
        const slicedCredits = credits.slice(startIndex, endIndex);
        const groupedData = groupByDate(slicedCredits);

        setCurrentPageItems(groupedData);
    }, [startIndex, endIndex, credits]);

    const findCurrentPlan = (final_plan) => {
        return plansNew.find((plan) => plan.levels.map((level) => level.id).includes(final_plan))
    }

    return (
        <Paper square={false} elevation={0} sx={{ backgroundColor: "#f7f8f9", p: "15px 20px", mt: 2 }}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h1">{t("credits.recordCredits")}</Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={4} alignItems="center">
                        <FiltersButton
                            franchise_id={franchise_id}
                            company_id={company_id}
                            setShowSkeleton={setShowSkeleton}
                            setCredits={setCredits}
                            setCreditsOG={setCreditsOG}
                            usersFranq={usersFranq}
                            showSkeleton={showSkeleton}
                        />
                        <BaseFillTextField label={t("team.search")} value={search} onChange={handleChangeSearch} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {showSkeleton
                        ? rowsSkeleton.map((item) => (
                            <Box sx={{ p: 1 }} key={item.id}>
                                {item.credits}
                            </Box>
                        ))
                        : Object.entries(currentPageItems).map(([date, items]) => (
                            <Box key={date}>
                                <Typography>
                                    {language === "en"
                                        ? moment.utc(date).locale("en").format("MMMM Do, YYYY")
                                        : `${moment.utc(date).format("D")} ${t("dashboard.pagingOf")} ${moment.utc(
                                            date
                                        ).format("MMMM")} ${t("credits.from")} ${moment.utc(date).format("YYYY")}`}
                                </Typography>
                                <Divider />
                                {items.map((item) => (
                                    <List className={classes.list} key={item._id}>
                                        <ListItem
                                            secondaryAction={
                                                <Grid container alignItems="center" spacing={4}>
                                                    {item.movement === 1 && (
                                                        <Grid item>
                                                            <Typography>
                                                                {t("credits.expiredTo")}{" "}
                                                                {moment
                                                                    .utc(item.roll_over_date)
                                                                    .format(todos.dateFormat)}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    <Grid item>
                                                        <Typography
                                                            style={{
                                                                color: item.movement === 1 ? "#00B147" : "#FF007A",
                                                            }}
                                                            fontSize={20}
                                                            fontWeight={600}
                                                        >
                                                            {item.movement === 1 ? "+" : "-"}
                                                            {item.quantity}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        >
                                            <ListItemText
                                                primary={
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <Typography variant="h4" sx={{ pl: 0.5 }}>
                                                            {item.source !== 6 &&
                                                                item.source !== 7 &&
                                                                item.source !== 4 &&
                                                                item.source !== 5 &&
                                                                item.source !== 0 &&
                                                                item.source !== 8 &&
                                                                item.source !== 9 &&
                                                                t("credits.buyTo")}
                                                            {(item.source === 5 || item.source === 0) &&
                                                                t("credits.expirationOf")}
                                                            {item.source === 6 && t("subscription.conversion")}
                                                            {item.source === 4 && t("navBar.freeTrial") + " - "}{" "}
                                                            {(item.source === 1 || item.source === 5) && (
                                                                <span style={{ color: "#4A22D4" }}>
                                                                    {t("subscription.subsPlan")}{" "}{item?.final_plan >= 0 ? findCurrentPlan(item.final_plan).planTitle : ""}
                                                                    {item?.final_plan >= 0
                                                                        ? " - " +  findCurrentPlan(item.final_plan).levels.find((level) => level.id === item.final_plan).pages + " " + t("register.pagesMonthly")
                                                                        : " - " +  item.quantity}
                                                                </span>
                                                            )}
                                                            {item.source === 0 && (
                                                                <span style={{ color: "#4A22D4" }}>
                                                                    {t("credits.converExtra")}
                                                                </span>
                                                            )}
                                                            {(item.source === 2 || item.source === 3) && (
                                                                <span style={{ color: "#4A22D4" }}>
                                                                    {t("subscription.packageOf")} {item.quantity}{" "}
                                                                    {item.quantity === 1 ? t("credits.conversion") : t("credits.credits")}
                                                                </span>
                                                            )}
                                                            {item.source === 4 && (
                                                                <span style={{ color: "#4A22D4" }}>
                                                                    Plan {" "}{item?.final_plan >= 0 ? findCurrentPlan(item.final_plan).planTitle : ""}
                                                                    {item?.final_plan >= 0 
                                                                        ? " - " +  findCurrentPlan(item.final_plan).levels.find((level) => level.id === item.final_plan).pages + " " + t("register.pagesMonthly")
                                                                        : " - " +  item.quantity}
                                                                </span>
                                                            )}
                                                            {item.source === 6 && (
                                                                <>
                                                                    <span style={{ color: "#4A22D4" }}>
                                                                        {item.conversion_id?.correlative
                                                                            ? "#" +
                                                                            zeroPad(item.conversion_id?.correlative, 5)
                                                                            : ""}
                                                                        {item.consolidated_conversion_id?.correlative
                                                                            ? "#" +
                                                                            zeroPad(item.consolidated_conversion_id?.correlative, 5)
                                                                            : ""}
                                                                        {item.check_conversion_id?.correlative
                                                                            ? "#" +
                                                                            zeroPad(item.check_conversion_id?.correlative, 5)
                                                                            : ""}
                                                                        {item.invoice_conversion_id?.correlative
                                                                            ? "#" +
                                                                            zeroPad(item.invoice_conversion_id?.correlative, 5)
                                                                            : ""}
                                                                    </span>
                                                                        {item.conversion_id?.correlative
                                                                            ? " (" + t("converter.bankState") + ")"
                                                                            : ""}
                                                                        {item.consolidated_conversion_id?.correlative
                                                                            ? " (" + t("converter.consolidatedInvoices") + ")"
                                                                            : ""}
                                                                        {item.check_conversion_id?.correlative
                                                                            ? " (" + t("converter.checks") + ")"
                                                                            : ""}
                                                                        {item.invoice_conversion_id?.correlative
                                                                            ? " (" + t("converter.separateInv") + ")"
                                                                            : ""}
                                                                    <span style={{ fontWeight: 600 }}>
                                                                        {item.account_id?.company_id
                                                                            ? " -  " +
                                                                            item.account_id?.company_id.company_name
                                                                            : ""}
                                                                    </span>
                                                                    <span style={{ fontWeight: 400 }}>
                                                                        {item.account_id
                                                                            ? " -  " + item.account_id.account_name
                                                                            : ""}
                                                                    </span>
                                                                    <span style={{ fontWeight: 400 }}>
                                                                        {item.user_id
                                                                            ? " |  " + item.user_id.user_name
                                                                            : " |  Customer support" }
                                                                    </span>
                                                                </>
                                                            )}
                                                            {item.source === 9 && (
                                                                <>
                                                                    <span>
                                                                        {t("credits.conversionDelay")}
                                                                    </span>{" "}
                                                                    <span style={{ color: "#4A22D4" }}>
                                                                        {item.conversion_id?.correlative
                                                                            ? "#" +
                                                                            zeroPad(item.conversion_id?.correlative, 5)
                                                                            : ""}
                                                                    </span>
                                                                    <span style={{ fontWeight: 600 }}>
                                                                        {item.account_id?.company_id
                                                                            ? " -  " +
                                                                            item.account_id?.company_id.company_name
                                                                            : ""}
                                                                    </span>
                                                                    <span style={{ fontWeight: 400 }}>
                                                                        {item.account_id
                                                                            ? " -  " + item.account_id.account_name
                                                                            : ""}
                                                                    </span>
                                                                    <span style={{ fontWeight: 400 }}>
                                                                        {item.user_id
                                                                            ? " |  " + item.user_id.user_name
                                                                            : " |  Customer support" }
                                                                    </span>
                                                                </>
                                                            )}
                                                            {item.source === 7 && (
                                                                <span style={{ fontWeight: 600 }}>
                                                                    {t("credits.freeTrialExpiration")}
                                                                </span>
                                                            )}
                                                            {item.source === 8 && (
                                                                <span style={{ fontWeight: 600 }}>
                                                                    {t("credits.switchPlan")}
                                                                </span>
                                                            )}{" "}
                                                            {item.source === 8 && item?.final_plan >= 0 
                                                                ? plansInfo.find(
                                                                    (plan) => plan.planId === item.final_plan
                                                                ).conversions
                                                                : ""}
                                                        </Typography>
                                                        {(item.quantity === 0 || item.source === 9) &&
                                                            <><LightTooltip
                                                                title={<Typography fontSize={12}>
                                                                    {t("credits.caseMsg")}
                                                                </Typography>}
                                                            >
                                                                <InfoIcon sx={{ fontSize: 14 }} color="primary" />
                                                            </LightTooltip></>}
                                                    </Stack>

                                                }
                                            />
                                        </ListItem>
                                    </List>
                                ))}
                            </Box>
                        ))}
                    <Grid container justifyContent="flex-end" alignItems="center">
                        {credits.length >= 25 && (
                            <>
                                <span>{t("dashboard.rowsPerPage")}</span>
                                <Select
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                    displayEmpty
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    sx={{
                                        marginLeft: "2px",
                                        "& fieldset": {
                                            border: "none"
                                        },
                                        "& fieldset.hover": {
                                            border: "none"
                                        },
                                        "& fieldset.active": {
                                            border: "none"
                                        },
                                    }}
                                >
                                    {itemsPerPageOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                        <span style={{
                            marginLeft: "25px",
                            marginRight: "30px"
                        }}>
                            {startIndex + 1}-{endIndex} {t("navBar.of")} {credits.length}
                        </span>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            sx={{
                                "& button": {
                                    minWidth: "16px !important",
                                    width: "25px",
                                    height: "25px",
                                    fontSize: "15px !important",
                                    lineHeight: "1.75",
                                    padding: "6px 8px",
                                    color: "#031851",
                                    margin: 0,
                                    fontWeight: 600,
                                    borderRadius: "10px",
                                },
                                "& button:active": {
                                    backgroundColor: "rgba(47,49,144,0.3) !important",
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Paper >
    );
};

export default CreditsRecord;
