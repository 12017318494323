import {
    Box,
    Button,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    Switch,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    IconButton,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import SyncIcon from "@mui/icons-material/Sync";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import Loading from "../../../assets/loading-gray.png";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import classes from "../MainConverter.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BaseSelect from "../../ui/BaseSelect";
import LightTooltip from "../../ui/LightTooltip";
import invoicesService from "../../../services/invoices";
import InvoicesSync from "./InvoicesSync";
import moment from "moment";
import InvoicesHistSync from "./InvoicesHistSync";
import InvoicesHistSyncDetails from "./InvoicesHistSyncDetails";

import { fetchFileFromS3, getFileExtension, zeroPad } from "../../../utils/utils";
import BaseActionsPreviewFile from "../BaseActionsPreviewFile";
import { useLocation } from "react-router-dom";
import { addChangeRoute } from "../../../redux/userSlice";

// This line is important for the correct functioning of the library
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.03)",
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const InvoicesPreview = (props) => {
    const {
        handleClickDownload,
        removeBanner,
        handleClickHelp,
        fileData,
        conversionData,
        checkedInvSigngs,
        setCheckedInvSigngs,
        checkedInvDay,
        setCheckedInvDay,
        fileNameFull,
        groupedData,
        setIsLoading,
        setOpenModalGrouped,
        setCorrelativeModalGrouped,
        setRowsModal,
        setOpenModalGroupedHist,
        setModalType,
        fileNameSaved,
        setShowSync,
        showSync,
        groupedPeriods,
        integration,
        reloadInvoices,
        returnInvoiceModal,
        setShowHistSync,
        showHistSync,
        setCorrelativeSync,
        setRows,
        setOpenPreview,
        setFileData,
        setShowSyncDetails,
        showSyncDetails,
        conversion_type,
        setBasePreviewData,
        basePreviewData,
        setOpenHeavy,
        setTexto,
        setAlertType,
        setShow,
        replaceInvBill,
        selectedInv,
    } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);
    const dispatch = useDispatch();

    const location = useLocation();
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsConv, setRowsConv] = useState([]);
    const [accountPrev, setAccountPrev] = useState(0);
    const [conversionDataNew, setConversionDataNew] = useState(groupedData ?? conversionData);
    const [conversionFile, setConversionFile] = useState(fileData);
    const [conversionFileName, setConversionFileName] = useState(fileNameFull);
    const [actualDocIndex, setActualDocIndex] = useState(-1);
    const [finalDateFormat, setFinalDateFormat] = useState(todos.dateFormat);
    const [dataHist, setDataHist] = useState([]);
    const [dataHistGroups, setDataHistGroups] = useState([]);
    const [detailsHist, setDetailsHist] = useState([]);
    const [invoicesStatus, setInvoicesStatus] = useState({
        success: 0,
        failed: 0,
        total: 0,
    });

    // 1: En proceso, 2: En revisión, 3: Por sincronizar, 4: Por descargar, 5: Sincronizando, 6: Sincronizado, 7: Registrado manual, 8: Sincronizado parcial, 9: Descargado, 10: Inválida, 11: Cancelado

    const success = groupedData?.invoices.filter(
        (item) => item.id_status === 3 || item.id_status === 4 || item.id_status === 6 || item.id_status === 9
    ).length;

    const revision = groupedData?.invoices.filter((item) => item.id_status === 2).length;

    const errConv = groupedData?.invoices.filter((item) => item.id_status === 10).length;

    const handleGoBack = () => {
        removeBanner();
        setRows([]);
        setRowsModal([]);
        setDataHist([]);
        setDataHistGroups([]);
        setDetailsHist([]);
        setShowSyncDetails(false);
        setShowHistSync(false);
        setInvoicesStatus({
            success: 0,
            failed: 0,
            total: 0,
        });
        reloadInvoices();
    };

    useEffect(() => {
        if (todos.keyRoute === "newCompany") {
            handleGoBack();
            dispatch(addChangeRoute(""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const handleGoList = () => {
        setShowSyncDetails(false);
        setShowHistSync(true);
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const nextPage = () => {
        setPageNumber((prevNum) => prevNum + 1);
    };

    const lastPage = () => {
        setPageNumber(numPages);
    };

    const prevPage = () => {
        setPageNumber((prevNum) => prevNum - 1);
    };

    const firstPage = () => {
        setPageNumber(1);
    };

    const handleSync = () => setShowSync(true);

    const handleHistSync = async (params) => {
        setIsLoading(true);
        await invoicesService
            .getHistorySync({ company_id: todos.companyID, invoice_type: 1, conversion_type, ...params })
            .then(async (data) => {
                let currentData = data.syncs.map((sync) => {
                    return {
                        ...sync,
                        name:
                            sync.grouped_invoice_id && sync.grouped_invoice_id !== null
                                ? "GROUP-" + zeroPad(sync.grouped_invoice_id.correlative ?? 0, 5)
                                : "#" + zeroPad(sync.invoices[0].correlative ?? 0, 5),
                    };
                });
                setDataHist(currentData);
                setDataHistGroups(data.groupsCorrelatives);
                let newData = null;

                if (selectedInv.isGrouped) {
                    let params2 = { grouped_invoice_id: selectedInv.id };
                    await invoicesService
                        .getGroupedInvoiceDetails(params2)
                        .then(async (data) => {
                            newData = data;
                            let statusSucc = 0;
                            let statusRev = 0;
                            let statusConv = 0;
                            let statusHeavy = 0;
                            let finalStatus = 0;

                            for (const value of data.invoices) {
                                if (value.id_status === 9) {
                                    statusSucc++;
                                } else if (value.id_status === 2) {
                                    statusRev++;
                                } else if (value.id_status === 1) {
                                    statusHeavy++;
                                } else if (value.id_status === 3) {
                                    statusConv++;
                                }
                            }

                            if (statusSucc > 0) {
                                finalStatus = 9;
                            } else if (statusRev > 0) {
                                finalStatus = 2;
                            } else if (statusHeavy > 0) {
                                finalStatus = 1;
                            } else if (statusConv > 0) {
                                finalStatus = 3;
                            } else {
                                finalStatus = 10;
                            }
                            newData.id_status = finalStatus;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    let params2 = { invoice_id: selectedInv.id };
                    await invoicesService
                        .getAIInvoiceDetails(params2)
                        .then((data) => {
                            newData = data;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }

                if (newData.invoices) {
                    const newArr = newData.invoices.map((item) => {
                        const foundInv = currentData.find((aux) => aux._id === item._id);
                        if (foundInv) {
                            item.id_status = foundInv.id_status;
                        }
                        return item;
                    });
                    setConversionDataNew({ ...newData, invoices: newArr });
                } else {
                    setConversionDataNew({ ...newData, id_status: currentData[0].invoices[0].id_status });
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setShowSync(false);
        setShowHistSync(true);
        setIsLoading(false);
    };

    const getFile = async (file_name) => {
        const data = await fetchFileFromS3(file_name);
        if (data) {
            setConversionFile(data);
        }
    };

    const handleChange = async (event) => {
        let newData = groupedData;
        let finalIndex;
        if (event.target.value !== 0) {
            finalIndex =  groupedData.invoices.findIndex(item => item._id === event.target.value)
            newData = groupedData.invoices.find((item) => item._id === event.target.value);
        } else {
            finalIndex = -1;
            newData = groupedData.invoices[0];
        }

        setActualDocIndex(finalIndex);

        setConversionFileName(newData.file_name);
        setPageNumber(1);
        setIsLoading(true);

        await getFile(newData.file_name);

        setIsLoading(false);

        setBasePreviewData({
            id: newData._id,
            type: "invoice",
        });

        if (event.target.value !== 0) {
            setConversionDataNew(newData);
            const newRows = [newData]
                .filter(
                    (item) =>
                        item.id_status === 3 || item.id_status === 4 || item.id_status === 6 || item.id_status === 9
                )
                .map((item) => {
                    let dateFormat = todos.dateFormat;

                    if (checkedInvDay) {
                        dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                    }

                    return {
                        id: item._id,
                        invoice: item.invoice_id ? item.invoice_id : "-",
                        contact: (item.conversion_type === 1 ? item.customer_name : item.vendor_name) ?? "-",
                        date: item.formatted_invoice_date
                            ? moment.utc(item.formatted_invoice_date).format(dateFormat)
                            : "-",
                        ref: item.control_number ? item.control_number : "-",
                        amount: item.formatted_invoice_total
                            ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            })
                            : "-",
                    };
                });
            setRowsConv(newRows);
        } else {
            setConversionDataNew(groupedData);
            const newRows = groupedData.invoices
                .filter(
                    (item) =>
                        item.id_status === 3 || item.id_status === 4 || item.id_status === 6 || item.id_status === 9
                )
                .map((item) => {
                    let dateFormat = todos.dateFormat;

                    if (checkedInvDay) {
                        dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                    }

                    return {
                        id: item._id,
                        invoice: item.invoice_id ? item.invoice_id : "-",
                        contact: (item.conversion_type === 1 ? item.customer_name : item.vendor_name) ?? "-",
                        date: item.formatted_invoice_date
                            ? moment.utc(item.formatted_invoice_date).format(dateFormat)
                            : "-",
                        ref: item.control_number ? item.control_number : "-",
                        amount: item.formatted_invoice_total
                            ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            })
                            : "-",
                    };
                });
            setRowsConv(newRows);
        }
        
        setAccountPrev(event.target.value);
    };

    const accountsPrev = groupedData
        ? [{ id: 0, name: fileNameSaved }].concat(
              groupedData.invoices.map((item) => ({
                  id: item._id,
                  name: "#" + zeroPad(item.correlative, 5) + " - " + item.invoice_id,
              }))
          )
        : [];

    let columns = [
        {
            name: "invoice",
            title: conversion_type === 1 ? t("payment.invoice") : t("accounts.ref"),
            align: "left",
            minWidth: 160,
        },
        { name: "contact", title: t("converter.contact"), align: "left" },
        { name: "date", title: t("payment.date"), align: "center" },
        { name: "ref", title: t("accounts.ref"), align: "left" },
        { name: "amount", title: t("credits.amount"), align: "right" },
    ];

    if (conversion_type === 2) {
        columns = columns.filter((item) => item.name !== "ref");
    }

    const handleChangeSwitchInvDay = (event) => {
        setCheckedInvDay(event.target.checked);
        setFinalDateFormat(finalDateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY");
    };

    useEffect(() => {
        if (conversionDataNew.invoices) {
            // Filtro para mostrar los line items de conversiones exitosas únicamente
            const newRows = conversionDataNew.invoices
                .filter(
                    (item) =>
                        item.id_status === 3 || item.id_status === 4 || item.id_status === 6 || item.id_status === 9
                )
                .map((item) => {
                    let dateFormat = todos.dateFormat;

                    if (checkedInvDay) {
                        dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                    }

                    return {
                        id: item._id,
                        invoice: item.invoice_id ? item.invoice_id : "-",
                        contact: (item.conversion_type === 1 ? item.customer_name : item.vendor_name) ?? "-",
                        date: item.formatted_invoice_date
                            ? moment.utc(item.formatted_invoice_date).format(dateFormat)
                            : "-",
                        ref: item.control_number ? item.control_number : "-",
                        amount: item.formatted_invoice_total
                            ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                                  minimumFractionDigits: 2,
                              })
                            : "-",
                    };
                });
            setRowsConv(newRows);
        }
    }, [checkedInvDay, checkedInvSigngs, t, todos.dateFormat, todos.amountFormat, conversionDataNew.invoices]);

    useEffect(() => {
        setCheckedInvSigngs(conversionDataNew.switch_signs ?? false);
        setCheckedInvDay(conversionDataNew.switch_day_month ?? false);
    }, [
        conversionDataNew.switch_day_month,
        conversionDataNew.switch_signs,
        setCheckedInvDay,
        setCheckedInvSigngs,
        setCorrelativeModalGrouped,
    ]);

    const handleNextDoc = async (direction) => {
        setIsLoading(true);
        let newData = {}
        let finalAcc;
        if (actualDocIndex + direction === -1) {
            newData = groupedData.invoices[0];
            finalAcc = 0;
        } else {
            newData = groupedData.invoices[actualDocIndex + direction];
            finalAcc = newData._id
        }

        setActualDocIndex(actualDocIndex + direction);

        setConversionFileName(newData.file_name);
        setPageNumber(1);

        await getFile(newData.file_name);

        setBasePreviewData({
            id: newData._id,
            type: "invoice",
        });

        if (actualDocIndex + direction !== -1) {
            setConversionDataNew(newData);
            const newRows = [newData]
                .filter(
                    (item) =>
                        item.id_status === 3 || item.id_status === 4 || item.id_status === 6 || item.id_status === 9
                )
                .map((item) => {
                    let dateFormat = todos.dateFormat;

                    if (checkedInvDay) {
                        dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                    }

                    return {
                        id: item._id,
                        invoice: item.invoice_id ? item.invoice_id : "-",
                        contact: (item.conversion_type === 1 ? item.customer_name : item.vendor_name) ?? "-",
                        date: item.formatted_invoice_date
                            ? moment.utc(item.formatted_invoice_date).format(dateFormat)
                            : "-",
                        ref: item.control_number ? item.control_number : "-",
                        amount: item.formatted_invoice_total
                            ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            })
                            : "-",
                    };
                });
            setRowsConv(newRows);
        } else {
            setConversionDataNew(groupedData);
            const newRows = groupedData.invoices
                .filter(
                    (item) =>
                        item.id_status === 3 || item.id_status === 4 || item.id_status === 6 || item.id_status === 9
                )
                .map((item) => {
                    let dateFormat = todos.dateFormat;

                    if (checkedInvDay) {
                        dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                    }

                    return {
                        id: item._id,
                        invoice: item.invoice_id ? item.invoice_id : "-",
                        contact: (item.conversion_type === 1 ? item.customer_name : item.vendor_name) ?? "-",
                        date: item.formatted_invoice_date
                            ? moment.utc(item.formatted_invoice_date).format(dateFormat)
                            : "-",
                        ref: item.control_number ? item.control_number : "-",
                        amount: item.formatted_invoice_total
                            ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            })
                            : "-",
                    };
                });
            setRowsConv(newRows);
        }

        setAccountPrev(finalAcc);

        setIsLoading(false);
    };

    const showConversions = (data, type) => {
        setModalType(type);
        setOpenModalGrouped(true);
        setOpenModalGroupedHist(true);
        setCorrelativeModalGrouped(groupedData?.correlative);

        const newRows = data.map((item) => ({
            id: item._id,
            invoice: item.invoice_id ?? "-",
            contact: (item.conversion_type === 1 ? item.customer_name : item.vendor_name) ?? "-",
            date: item.formatted_invoice_date ? moment.utc(item.formatted_invoice_date).format(todos.dateFormat) : "-",
            amount: item.formatted_invoice_total
                ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                      minimumFractionDigits: 2,
                  })
                : "-",
            number: item.correlative,
            file_name: item.uploaded_file,
            file_name_s3: item.file_name,
            period: "",
            status: item.id_status,
        }));
        setRowsModal(newRows);
    };

    const getPeriods = () => {
        if (accountPrev === 0 && groupedData) {
            let periodsDateFilter = groupedPeriods.filter((item) => item.formatted_invoice_date !== null);
            if (periodsDateFilter.length > 1) {
                return `${moment.utc(periodsDateFilter[0].formatted_invoice_date).format(todos.dateFormat)} - ${moment
                    .utc(periodsDateFilter[periodsDateFilter.length - 1].formatted_invoice_date)
                    .format(todos.dateFormat)}`;
            } else if (periodsDateFilter.length === 1) {
                return `${moment.utc(periodsDateFilter[0].formatted_invoice_date).format(todos.dateFormat)} - ${moment
                    .utc(periodsDateFilter[0].formatted_invoice_date)
                    .format(todos.dateFormat)}`;
            } else {
                return "N/A";
            }
        } else {
            return `${moment.utc(conversionDataNew.formatted_invoice_date).format(todos.dateFormat)} - ${moment
                .utc(conversionDataNew.formatted_invoice_date)
                .format(todos.dateFormat)}`;
        }
    };

    const goToSync = (type) => {
        let newInv = { ...conversionDataNew };
        if (conversionDataNew.invoices) {
            if (type === 0) {
                newInv.invoices = conversionDataNew.invoices.filter((item) => item.id_status === 3);
            } else {
                newInv.invoices = conversionDataNew.invoices.filter((item) => item.id_status === 6);
            }
        }

        setConversionDataNew(newInv);
        setShowHistSync(false);
        setShowSync(true);
    };

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: showSync ? "white" : "#F7F8FA" }}>
            <Stack direction="row" justifyContent="space-between" pt={5} mb={3}>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={showSyncDetails ? handleGoList : handleGoBack}
                    >
                        {showSyncDetails ? t("inbox.goBackList") : t("converter.goBackConver")}
                    </Button>
                    {showHistSync && (
                        <>
                            {(conversionDataNew.id_status === 3 ||
                                conversionDataNew.invoices?.find((item) => item.id_status === 3)) && (
                                <Button variant="outlined" onClick={() => goToSync(0)}>
                                    {t("converter.toBeSynch")}{" "}
                                    {conversionDataNew.invoices?.filter((item) => item.id_status === 3).length &&
                                        `(${
                                            conversionDataNew.invoices?.filter((item) => item.id_status === 3).length
                                        })`}
                                </Button>
                            )}
                            {(conversionDataNew.id_status === 6 ||
                                conversionDataNew.invoices?.find((item) => item.id_status === 6)) && (
                                <Button variant="outlined" onClick={() => goToSync(1)}>
                                    {t("converter.synced")}{" "}
                                    {conversionDataNew.invoices?.filter((item) => item.id_status === 6).length &&
                                        `(${
                                            conversionDataNew.invoices?.filter((item) => item.id_status === 6).length
                                        })`}
                                </Button>
                            )}
                        </>
                    )}
                </Stack>
                {(!groupedData || (groupedData && accountPrev !== 0)) && (
                    <Typography gutterBottom>
                        <Button onClick={() => handleClickHelp("conversion", "invoice", conversionDataNew._id)}>
                            {t("accounts.needHelp")}
                        </Button>
                    </Typography>
                )}
            </Stack>
            {showSync ? (
                <InvoicesSync
                    setIsLoading={setIsLoading}
                    handleGoBack={handleGoBack}
                    downloadInv={handleClickDownload}
                    invoicesData={conversionDataNew}
                    returnInvoiceModal={returnInvoiceModal}
                    setOpenPreview={setOpenPreview}
                    setFileData={setFileData}
                    handleHistSync={handleHistSync}
                    setBasePreviewData={setBasePreviewData}
                    setOpenHeavy={setOpenHeavy}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                    setShow={setShow}
                    replaceInvBill={replaceInvBill}
                    conversionType={conversion_type}
                />
            ) : showHistSync ? (
                <InvoicesHistSync
                    dataHist={dataHist}
                    dataHistGroups={dataHistGroups}
                    showConversions={showConversions}
                    setIsLoading={setIsLoading}
                    setCorrelativeSync={setCorrelativeSync}
                    setShowSyncDetails={setShowSyncDetails}
                    setShowHistSync={setShowHistSync}
                    setDetailsHist={setDetailsHist}
                    setInvoicesStatus={setInvoicesStatus}
                    replaceInvBill={replaceInvBill}
                />
            ) : showSyncDetails ? (
                <InvoicesHistSyncDetails
                    detailsHist={detailsHist}
                    invoicesStatus={invoicesStatus}
                    handleGoBack={handleGoBack}
                    replaceInvBill={replaceInvBill}
                />
            ) : (
                <>
                    {groupedData && (
                        <Grid container spacing={3} mt={0.2}>
                            <Grid item xs={12} md={5}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor: "white",
                                        p: 2,
                                        borderRadius: 4,
                                        boxShadow: "4px 4px 10px rgba(74, 34, 212, 0.1)",
                                    }}
                                >
                                    <Box sx={{ width: 250 }}>
                                        <BaseSelect
                                            value={accountPrev}
                                            values={accountsPrev}
                                            onChange={handleChange}
                                            label={replaceInvBill(t("payment.invoice"))}
                                        ></BaseSelect>
                                    </Box>
                                    <Stack justifyContent="end">
                                        <Typography variant="h2" textAlign="end">
                                            {groupedData.invoices.length} {replaceInvBill(t("converter.invoices"))}
                                        </Typography>
                                        <Typography>
                                            #
                                            {zeroPad(
                                                Math.min(...groupedData.invoices.map((item) => item.correlative)),
                                                5
                                            )}{" "}
                                            - #
                                            {zeroPad(
                                                Math.max(...groupedData.invoices.map((item) => item.correlative)),
                                                5
                                            )}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box className={classes.backWhiteDet}>
                                    <Stack direction="row" spacing={2} justifyContent="space-between">
                                        <Box>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography variant="h2" py={revision > 0 || errConv > 0 ? 0 : 0.5}>
                                                    {t("converter.conversionStatus")}
                                                </Typography>
                                                {(revision > 0 || errConv > 0) && (
                                                    <LightTooltip title={t("miscellaneous.toolHelpConvDet")}>
                                                        <HelpIcon fontSize="small" color="primary" />
                                                    </LightTooltip>
                                                )}
                                            </Stack>
                                            <Grid container spacing={2} sx={{ pl: 2 }}>
                                                {success > 0 && (
                                                    <Grid item>
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <Typography>{success}</Typography>
                                                            <CheckIcon fontSize="small" color="success" />
                                                        </Stack>
                                                    </Grid>
                                                )}
                                                {revision > 0 && (
                                                    <Grid item>
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <Typography>{revision}</Typography>
                                                            <AccessTimeIcon fontSize="small" color="warning" />
                                                        </Stack>
                                                    </Grid>
                                                )}
                                                {errConv > 0 && (
                                                    <Grid item>
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <Typography>{errConv}</Typography>
                                                            <CloseIcon fontSize="small" color="error" />
                                                        </Stack>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Box>
                                        <Button
                                            variant="outlined"
                                            endIcon={<SearchIcon style={{ width: "27px", height: "27px" }} />}
                                            onClick={() => showConversions(groupedData.invoices, "invoice")}
                                            sx={{
                                                py: 1.4,
                                                width: 180,
                                                justifyContent: "space-between",
                                                backgroundColor: "#F6F4FD",
                                                "&:hover": {
                                                    backgroundColor: "#E5E5E5",
                                                },
                                                "&:disabled": {
                                                    color: "#FFF",
                                                    backgroundColor: "rgba(189, 189, 189, 1)",
                                                },
                                            }}
                                        >
                                            {t("converter.seeDetails")}
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={6} pb={3}>
                        <Grid item xs={12} md={5}>
                            {(conversionFile || groupedData) && (
                                <>
                                    {getFileExtension(
                                        conversionFileName
                                            ? conversionFileName?.toLowerCase()
                                            : conversionFile.path?.toLowerCase()
                                    ) === "pdf" ? (
                                        <>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                sx={{ bgcolor: "#FFF", borderRadius: 2 }}
                                            >
                                                <IconButton disabled={pageNumber === 1} onClick={firstPage}>
                                                    <FirstPageIcon
                                                        sx={{
                                                            color: pageNumber === 1 ? "rgba(0, 0, 0, 0.26)" : "#4a22d4",
                                                        }}
                                                    />
                                                </IconButton>
                                                <IconButton disabled={pageNumber === 1} onClick={prevPage}>
                                                    <ArrowBackIcon
                                                        sx={{
                                                            color: pageNumber === 1 ? "rgba(0, 0, 0, 0.26)" : "#4a22d4",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography>
                                                    {pageNumber} - {numPages}
                                                </Typography>
                                                <IconButton disabled={pageNumber === numPages} onClick={nextPage}>
                                                    <ArrowForwardIcon
                                                        sx={{
                                                            color:
                                                                pageNumber === numPages
                                                                    ? "rgba(0, 0, 0, 0.26)"
                                                                    : "#4a22d4",
                                                        }}
                                                    />
                                                </IconButton>
                                                <IconButton disabled={pageNumber === numPages} onClick={lastPage}>
                                                    <LastPageIcon
                                                        sx={{
                                                            color:
                                                                pageNumber === numPages
                                                                    ? "rgba(0, 0, 0, 0.26)"
                                                                    : "#4a22d4",
                                                        }}
                                                    />
                                                </IconButton>
                                            </Stack>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <Box
                                        sx={{
                                            height: 650,
                                            overflow: "auto",
                                            "&::-webkit-scrollbar": {
                                                width: "10px",
                                                height: "10px",
                                            },
                                            "&::-webkit-scrollbar-track": {
                                                boxShadow: "inset 10px 10px 15px #c7c9d1",
                                                borderRadius: "10px",
                                            },
                                            "&::-webkit-scrollbar-thumb": {
                                                background: "#404963",
                                                borderRadius: "10px",
                                            },
                                            "&::-webkit-scrollbar-thumb:hover": {
                                                background: "#031851",
                                            },
                                        }}
                                    >
                                        {getFileExtension(
                                            conversionFileName
                                                ? conversionFileName?.toLowerCase()
                                                : conversionFile.path?.toLowerCase()
                                        ) === "pdf" ? (
                                            <>
                                                <Document file={conversionFile} onLoadSuccess={onDocumentLoadSuccess}>
                                                    <Page pageNumber={pageNumber} />
                                                </Document>
                                            </>
                                        ) : (
                                            <Box
                                                className={classes.workbookContainer}
                                                sx={{ bgcolor: "#CCC" }}
                                                justifyContent="center"
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box
                                                    sx={{ width: "100%", height: "auto" }}
                                                    component="img"
                                                    src={conversionFile}
                                                ></Box>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            bgcolor: "#FFF",
                                            borderRadius: 2,
                                            display: "flex",
                                            justifyContent: "end",
                                            px: 2,
                                        }}
                                    >
                                        <BaseActionsPreviewFile basePreviewData={basePreviewData} />
                                    </Box>
                                </>
                            )}
                            {groupedData && (
                                <Stack direction="row" spacing={1} justifyContent="center">
                                    <Button
                                        endIcon={<ArrowBackIcon />}
                                        sx={{ color: actualDocIndex === -1 ? "rgba(0, 0, 0, 0.26)" : "#131f3e" }}
                                        disabled={actualDocIndex === -1}
                                        onClick={() => handleNextDoc(-1)}
                                    >
                                        {t("converter.previous")}
                                    </Button>
                                    <Button
                                        startIcon={<ArrowForwardIcon />}
                                        sx={{
                                            color:
                                                actualDocIndex === groupedData.invoices.length - 1
                                                    ? "rgba(0, 0, 0, 0.26)"
                                                    : "#131f3e",
                                        }}
                                        disabled={actualDocIndex === groupedData.invoices.length - 1}
                                        onClick={() => handleNextDoc(1)}
                                    >
                                        {t("miscellaneous.next")}
                                    </Button>
                                </Stack>
                            )}
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid container spacing={2} sx={{ pt: groupedData ? 2 : 0 }}>
                                <Grid item xs={12} md={6} justifyContent="center">
                                    <Typography variant="h1" color="primary" gutterBottom>
                                        {t("converter.resumeConv")}
                                    </Typography>
                                    {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                        <Typography gutterBottom>
                                            <b>{t("converter.file")}:</b> {conversionDataNew.uploaded_file ?? "-"}
                                        </Typography>
                                    )}
                                    {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                        <Typography gutterBottom>
                                            <b>{conversion_type === 1 ? t("payment.invoice") : t("accounts.ref")}:</b>{" "}
                                            {conversionDataNew.invoice_id ?? "-"}
                                        </Typography>
                                    )}
                                    {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                        <Typography gutterBottom>
                                            <b>{t("converter.contact")}:</b>{" "}
                                            {(conversionDataNew.conversion_type === 1
                                                ? conversionDataNew.customer_name
                                                : conversionDataNew.vendor_name) ?? "-"}
                                        </Typography>
                                    )}
                                    {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                        <Typography gutterBottom>
                                            <b>{t("payment.date")}:</b>{" "}
                                            {conversionDataNew.formatted_invoice_date
                                                ? moment
                                                      .utc(conversionDataNew.formatted_invoice_date)
                                                      .format(todos.dateFormat)
                                                : "-"}
                                        </Typography>
                                    )}
                                    {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                        <Typography gutterBottom>
                                            <b>{t("credits.amount")}:</b>{" "}
                                            {conversionDataNew.formatted_invoice_total
                                                ? parseFloat(conversionDataNew.formatted_invoice_total).toLocaleString(
                                                      todos.amountFormat,
                                                      {
                                                          minimumFractionDigits: 2,
                                                      }
                                                  )
                                                : "-"}
                                        </Typography>
                                    )}
                                    {groupedData && accountPrev === 0 && (
                                        <Typography gutterBottom mt={2}>
                                            <b>{t("payment.period")}:</b> {getPeriods()}
                                        </Typography>
                                    )}
                                    {groupedData && accountPrev === 0 && (
                                        <Typography gutterBottom mt={1.5}>
                                            <b>{replaceInvBill(t("converter.numInvoices"))}:</b>{" "}
                                            {accountPrev === 0 && groupedData ? rowsConv.length : ""}
                                        </Typography>
                                    )}
                                    {groupedData && accountPrev === 0 && (
                                        <Typography gutterBottom mt={1.5}>
                                            <b>{t("converter.totalAmount")}:</b>{" "}
                                            {accountPrev === 0 && groupedData
                                                ? parseFloat(
                                                      groupedPeriods.reduce((sum, item) => {
                                                          return sum + item.formatted_invoice_total;
                                                      }, 0)
                                                  ).toLocaleString(todos.amountFormat, {
                                                      minimumFractionDigits: 2,
                                                  })
                                                : parseFloat(conversionDataNew.formatted_invoice_total).toLocaleString(
                                                      todos.amountFormat,
                                                      {
                                                          minimumFractionDigits: 2,
                                                      }
                                                  )}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h1" color="primary" gutterBottom>
                                        {t("converter.transform")}
                                    </Typography>
                                    <LightTooltip
                                        title={groupedData && accountPrev !== 0 ? t("converter.tooltSwitch") : ""}
                                    >
                                        <FormGroup>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                justifyContent="left"
                                                alignItems="center"
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={checkedInvDay}
                                                            onChange={handleChangeSwitchInvDay}
                                                        />
                                                    }
                                                    label={t("converter.invertDayMonth")}
                                                />
                                                <InfoIcon color="primary" fontSize="small" />
                                            </Stack>
                                        </FormGroup>
                                    </LightTooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h1" color="primary" gutterBottom>
                                        {t("converter.preview")}
                                    </Typography>
                                    {conversionDataNew.large_conversion &&
                                    conversionDataNew.large_conversion === true ? (
                                        <Box mt={2}>{t("converter.largeConvMsg")}</Box>
                                    ) : groupedData ? (
                                        <>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <StyledTableRow>
                                                            {columns.map((item) => (
                                                                <StyledTableCell
                                                                    key={item.name}
                                                                    align={item.align ?? "left"}
                                                                    style={{ minWidth: item.minWidth }}
                                                                >
                                                                    {item.title}
                                                                </StyledTableCell>
                                                            ))}
                                                        </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rowsConv.slice(0, 5).map((row) => (
                                                            <StyledTableRow
                                                                key={row.id}
                                                                sx={{
                                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                                }}
                                                            >
                                                                <StyledTableCell align="left">
                                                                    {row.invoice}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    {row.contact}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center">
                                                                    {row.date}
                                                                </StyledTableCell>
                                                                {conversion_type === 1 && (
                                                                    <StyledTableCell align="left">
                                                                        {row.ref}
                                                                    </StyledTableCell>
                                                                )}
                                                                <StyledTableCell align="right">
                                                                    {row.amount}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    ) : (
                                        <>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <StyledTableRow>
                                                            {columns.map((item) => (
                                                                <StyledTableCell
                                                                    key={item.name}
                                                                    align={item.align ?? "left"}
                                                                    style={{ minWidth: item.minWidth }}
                                                                >
                                                                    {item.title}
                                                                </StyledTableCell>
                                                            ))}
                                                        </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <StyledTableRow
                                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                        >
                                                            <StyledTableCell align="left">
                                                                {conversionDataNew.invoice_id ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {(conversionDataNew.conversion_type === 1
                                                                    ? conversionDataNew.customer_name
                                                                    : conversionDataNew.vendor_name) ?? "-"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {conversionDataNew.formatted_invoice_date
                                                                    ? moment
                                                                          .utc(conversionDataNew.formatted_invoice_date)
                                                                          .format(finalDateFormat)
                                                                    : "-"}
                                                            </StyledTableCell>
                                                            {conversion_type === 1 && (
                                                                <StyledTableCell align="left">
                                                                    {conversionDataNew.control_number ?? "-"}
                                                                </StyledTableCell>
                                                            )}
                                                            <StyledTableCell align="right">
                                                                {conversionDataNew.formatted_invoice_total
                                                                    ? parseFloat(
                                                                          conversionDataNew.formatted_invoice_total
                                                                      ).toLocaleString(todos.amountFormat, {
                                                                          minimumFractionDigits: 2,
                                                                      })
                                                                    : "-"}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={3} justifyContent="center">
                                        <Button
                                            size="large"
                                            variant="outlined"
                                            disableElevation
                                            endIcon={<DownloadIcon />}
                                            onClick={handleClickDownload}
                                            sx={{
                                                py: 1,
                                                px: 4.3,
                                            }}
                                        >
                                            {t("payment.download")}
                                        </Button>
                                        {(
                                            conversionDataNew.invoices
                                                ? conversionDataNew.invoices.every((item) => item.id_status === 3)
                                                : conversionDataNew.id_status !== 6 && conversionDataNew.id_status !== 8
                                        ) ? (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                disableElevation
                                                endIcon={
                                                    groupedData ? (
                                                        groupedData.invoices.some((item) => item.id_status === 5) ? (
                                                            <img src={Loading} alt="loading" width={20} />
                                                        ) : (
                                                            <SyncIcon />
                                                        )
                                                    ) : conversionDataNew.id_status === 5 ? (
                                                        <img src={Loading} alt="loading" width={20} />
                                                    ) : (
                                                        <SyncIcon />
                                                    )
                                                }
                                                onClick={handleSync}
                                                sx={{
                                                    py: 1,
                                                    px: 3.5,
                                                }}
                                                disabled={
                                                    (groupedData
                                                        ? !groupedData.invoices.some((item) => item.id_status === 3)
                                                        : conversionDataNew.id_status !== 3) ||
                                                    (integration !== 1 && integration !== 2)
                                                }
                                            >
                                                {groupedData
                                                    ? groupedData.invoices.some((item) => item.id_status === 5)
                                                        ? t("converter.synchronizing")
                                                        : t("converter.sync")
                                                    : conversionDataNew.id_status === 5
                                                    ? t("converter.synchronizing")
                                                    : t("converter.sync")}
                                            </Button>
                                        ) : (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                disableElevation
                                                endIcon={<SyncIcon />}
                                                onClick={() =>
                                                    handleHistSync(
                                                        groupedData
                                                            ? { grouped_invoice_id: groupedData._id }
                                                            : { invoice_id: conversionDataNew._id }
                                                    )
                                                }
                                                sx={{
                                                    py: 1,
                                                    px: 3.5,
                                                }}
                                                disabled={integration !== 1 && integration !== 2}
                                            >
                                                {t("converter.seeSync")}
                                            </Button>
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                        <InfoIcon color="primary" fontSize="small" />
                                        <Typography variant="subtitle1" color="#131f3e">
                                            {t("converter.syncInfoMsg")}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Container>
    );
};

export default InvoicesPreview;
