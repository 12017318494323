import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddIcon from "@mui/icons-material/Add";

import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FiltersButton from "./FiltersButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const checkIconDown = (props) => {
    if (props.className.includes("MuiSelect-iconOpen")) {
        return (
            <PlayArrowIcon
                sx={{
                    position: "absolute",
                    transform: "rotate(270deg)",
                    color: "#131F3E",
                    right: ".5rem",
                    cursor: "pointer",
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            />
        );
    }
    return (
        <PlayArrowIcon
            sx={{
                position: "absolute",
                transform: "rotate(90deg)",
                color: "#131F3E",
                right: ".5rem",
                cursor: "pointer",
                zIndex: 0,
                pointerEvents: "none",
            }}
        />
    );
};

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
            since: "",
            until: "",
        },
    ],
};

const IcomeTableDet = (props) => {
    const { conversionData, accountsXero, xeroTaxRates, accounts, setAccount } = props;
    const [selected, setSelected] = useState([]);
    const [rows, setRows] = useState(conversionData.line_items);
    const [filters, setFilters] = useState(defaultFilter);

    const todos = useSelector((state) => state.value);
    const [t] = useTranslation("global");

    const columns = [
        { name: "description", title: t("accounts.description"), width: "15%" },
        { name: "type", title: <></>, width: "8%" },
        { name: "quantity", title: t("converter.qty"), align: "right" },
        { name: "amount", title: "Monto USD", align: "right" },
        { name: "account", title: "Cuenta contable" },
        { name: "tax", title: "Impuesto" },
        { name: "total", title: "Total USD", align: "right" },
        { name: "actions", title: <></>, width: "8%" },
    ];

    const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substring(2, 9);

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleEditRow = (id) => {};

    const handleNewRow = () => {
        let newRowId = generateUniqueId();
        setRows([
            ...rows,
            {
                new: true,
                new_line: true,
                _id: newRowId,
            },
        ]);
    };

    const handleChangeSelectTax = (event, id) => {
        const newRows = rows.map((aux) => {
            if (id === aux._id) {
                return {
                    ...aux,
                    tax_type: event.target.value,
                };
            } else {
                return aux;
            }
        });
        setRows(newRows);
    };

    const handleChangeSelectAcc = (event, id) => {
        const newRows = rows.map((aux) => {
            if (id === aux._id) {
                return {
                    ...aux,
                    account_code: event.target.value,
                };
            } else {
                return aux;
            }
        });
        setRows(newRows);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClickCheck = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleClickType = () => {};

    return (
        <Box
            sx={{
                backgroundColor: "#F8F8F9",
                px: 3,
                py: 2,
                borderRadius: 4,
                mt: 2,
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h2">Lineas del reporte extraídas</Typography>
                <FiltersButton filters={filters} setFilters={setFilters} accounts={accounts} setAccount={setAccount} />
            </Stack>

            <TableContainer>
                <Table
                    sx={{
                        borderCollapse: "separate",
                        borderSpacing: "0 6px",
                        minWidth: 1300,
                    }}
                >
                    <TableHead>
                        <TableRow
                            sx={{
                                "& th": { border: "none" },
                            }}
                        >
                            <StyledTableCell>
                                <Checkbox
                                    color="primary"
                                    indeterminate={selected.length > 0 && selected.length < rows.length}
                                    checked={rows.length > 0 && selected.length === rows.length}
                                    onChange={handleSelectAllClick}
                                />
                            </StyledTableCell>
                            {columns.map((headCell) => (
                                <StyledTableCell
                                    key={headCell.name}
                                    align={headCell.align ?? "left"}
                                    style={{
                                        width: headCell.width,
                                    }}
                                >
                                    {headCell.title}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            const isItemSelected = isSelected(row._id);
                            return (
                                <StyledTableRow key={row._id}>
                                    <StyledTableCell>
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            onClick={() => handleClickCheck(row._id)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>{row.description}</StyledTableCell>
                                    <StyledTableCell>
                                        <Chip
                                            label={row.credit ? "Ingreso" : "Gasto"}
                                            size="small"
                                            sx={{
                                                backgroundColor: row.credit ? "#C2FFFF" : "#FFF1DB",
                                                fontWeight: 600,
                                                px: 2,
                                                width: 110,
                                            }}
                                            onClick={handleClickType}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.formatted_quantity}</StyledTableCell>
                                    <StyledTableCell align="right">
                                        {parseFloat(row.formatted_credit ?? 0).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="demo-simple-select-label" sx={{ color: "#131f3e" }}>
                                                Cuenta contable
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                name="account"
                                                value={row.account_code ?? ""}
                                                onChange={(event) => handleChangeSelectAcc(event, row._id)}
                                                IconComponent={(props) => checkIconDown(props)}
                                                size="small"
                                                MenuProps={MenuProps}
                                            >
                                                {accountsXero.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="demo-simple-select-label" sx={{ color: "#131f3e" }}>
                                                Impuesto
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                name="tax_type"
                                                value={row.tax_type ?? ""}
                                                onChange={(event) => handleChangeSelectTax(event, row._id)}
                                                IconComponent={(props) => checkIconDown(props)}
                                                size="small"
                                                MenuProps={MenuProps}
                                            >
                                                {xeroTaxRates.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {parseFloat(row.formatted_quantity * row.formatted_credit).toLocaleString(
                                            todos.amountFormat,
                                            {
                                                minimumFractionDigits: 2,
                                            }
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Stack direction="row" spacing={1}>
                                            <IconButton size="small" onClick={() => handleEditRow(row._id)}>
                                                <EditIcon
                                                    sx={{
                                                        color: "#4A22D4",
                                                    }}
                                                />
                                            </IconButton>
                                            <IconButton size="small" onClick={() => handleEditRow(row._id, false)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="outlined" sx={{ mt: 1 }} disableElevation endIcon={<AddIcon />} onClick={handleNewRow}>
                Agregar fila
            </Button>
        </Box>
    );
};

export default IcomeTableDet;
