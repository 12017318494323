import {
    Box,
    Grid,
    InputAdornment,
    Table,
    TableContainer,
    TableHead,
    TextField,
    Typography,
    TableRow,
    TableBody,
    TableFooter,
    TablePagination,
    Skeleton,
    Stack,
    IconButton,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import FiltersButton from "./FiltersButton";

import SearchIcon from "@mui/icons-material/Search";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import ChatIcon from "../../../assets/chat-icon.svg";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import TablePaginationActions from "../../ui/TablePagActions";

import reportService from "../../../services/reports";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LightTooltip from "../../ui/LightTooltip";
import { fetchFileFromS3, formatAmount, formatDate, zeroPad } from "../../../utils/utils";
import casesService from "../../../services/case";
import { initiateSocket, newUserResponse, subscribeToChat } from "../../../services/Socket";

const StyledTableRowB = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        convertion: <Skeleton variant="text" animation="wave" />,
        bankAcc: <Skeleton variant="text" animation="wave" />,
        date: <Skeleton variant="text" animation="wave" />,
        period: <Skeleton variant="text" animation="wave" />,
        gainNe: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        chat: <Skeleton variant="text" animation="wave" />,
    });
}

const IncomeTable = (props) => {
    const { filters, setFilters, accounts, setAccount, account, isReload, setIsLoading, setConversionData, setPreviewInc, setConversionId, setTexto, setAlertType, setShow, setFileData, setFileNameFull, setBasePreviewData } = props;
    const [t] = useTranslation("global");

    const columns = [
        { name: "convertion", title: "Conversión" },
        { name: "bankAcc", title: "Cuenta bancaria" },
        { name: "date", title: "Fecha de conversión", align: "center" },
        { name: "period", title: "Período" },
        { name: "gainNe", title: "Ingreso neto", align: "right" },
        { name: "user", title: "Usuario" },
        { name: "status", title: "Estatus" },
    ];

    const todos = useSelector((state) => state.value);
    const location = useLocation();

    const [searchValue, setSearchValue] = useState("");
    const [rows, setRows] = useState(rowsSkeleton);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [caseInfo, setCaseInfo] = useState({});
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
        (async () => {
            await reportService
                .getReportConversions({
                    company_id: todos.companyID,
                    account_id: location.state?.account_id ?? account,
                })
                .then((response) => {
                    setRows(response);
                })
                .catch((err) => {
                    console.log(err);
                });
        })();
    }, [account, location.state?.account_id, todos.companyID, isReload]);

    const handleShowPrev = async (convID, AccID) => {
        setIsLoading(true);
        let converInfo = {};
        let params2 = { report_id: convID };
        await reportService
            .getReportDetails(params2)
            .then(async (data) => {
                setConversionData(data);
                // setRepeatedConv(data.repeatedConversion);
                converInfo = data
            })
            .catch((err) => {
                console.log(err);
            });
        // setCorrelative(converInfo.correlative);
        setConversionId(converInfo._id);
        // setFileNameSaved(converInfo.download_file_name.replace(/\.[^/.]+$/, ""));
        const finalData = await fetchFileFromS3(converInfo.file_name);
        if (!finalData) {
            setTexto(t("converter.dnldError"));
            setAlertType("error");
            setShow(true);
        } else {
            setFileData(finalData);
        }
        setFileNameFull(converInfo.uploaded_file);
        setBasePreviewData({
            type: "report",
            file_s3: converInfo.file_name,
            file: converInfo.uploaded_file,
        });
        // setAccount(AccID);
        setIsLoading(false);
        setPreviewInc(true);
    };

    const connectSocket = async (case_id, correlative, status) => {
        setRoom(case_id);
        initiateSocket(case_id, todos.userInfo, "check", correlative);
        subscribeToChat((err, data) => {
            if (err) return;
            setChat((oldChats) => [...oldChats, data]);
        });
        newUserResponse((err, data) => {
            if (err) return;
            setUsers(data);
        });
        setOpen(true);
    };

    const handleClickChat = async (statusConv, conversionID, correlative) => {
        setIsLoading(true);

        let params = {
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            user_email: todos.userInfo.email,
            type: "conversion",
            conversion_id: conversionID,
            conversion_type: "check",
        };

        let caseInfo = null;

        await casesService
            .getCaseInfo(params)
            .then(async (response) => {
                if (response) {
                    setCaseInfo(response.caseInfo);
                    caseInfo = response.caseInfo;
                }
            })
            .catch((err) => {
                console.log(err);
            });

        if (!caseInfo) {
            await casesService
                .createNewCase(params)
                .then(async () => {
                    await casesService
                        .getCaseInfo(params)
                        .then(async (data) => {
                            setCaseInfo(data.caseInfo);
                            caseInfo = data.caseInfo;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        await connectSocket(caseInfo._id, correlative, statusConv);

        setIsLoading(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);

        const filterCases = rows.filter(
            (item) =>
                item.company_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.plans.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.integ.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setRows(filterCases);
    };

    return (
        <>
            <Box
                component="main"
                sx={{
                    mx: { xs: 2, md: 2 },
                    px: { xs: 2, md: 3 },
                    backgroundColor: "#F8F8F9",
                    pt: 3,
                    borderRadius: 3,
                    mt: 2,
                }}
            >
                <Typography variant="h1">Historial de reportes de ingreso</Typography>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <FiltersButton
                            filters={filters}
                            setFilters={setFilters}
                            accounts={accounts}
                            setAccount={setAccount}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="input-with-icon-textfield"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="filled"
                            hiddenLabel
                            placeholder={t("team.search")}
                            value={searchValue}
                            onChange={handleChangeSearchValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table
                                size="small"
                                sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1400 }}
                            >
                                <TableHead>
                                    <StyledTableRowB>
                                        {columns.map((item) => (
                                            <StyledTableCell
                                                key={item.name}
                                                align={item.align ?? "left"}
                                                style={{ minWidth: item.minWidth }}
                                            >
                                                {item.title}
                                            </StyledTableCell>
                                        ))}
                                    </StyledTableRowB>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row) => (
                                        <StyledTableRow
                                            key={row.id}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <StyledTableCell>#{zeroPad(row.correlative, 5)}</StyledTableCell>
                                            <StyledTableCell>{row.account_id?.account_name}</StyledTableCell>
                                            <StyledTableCell align="center">{formatDate(row.current_date, todos.dateFormat)}</StyledTableCell>
                                            <StyledTableCell>{formatDate(row.formatted_initial_date, todos.dateFormat)} - {formatDate(row.formatted_end_date, todos.dateFormat)}</StyledTableCell>
                                            <StyledTableCell align="right">{formatAmount(row.formatted_total_income + row.formatted_total_expenses, todos.amountFormat)}</StyledTableCell>
                                            <StyledTableCell>{row?.user_id?.user_name}</StyledTableCell>
                                            {/* // 1: En revisión, 2: Por sincronizar, 3: Sincronizado */}
                                            <StyledTableCell>
                                                <Stack direction="row" alignItems="center">
                                                    {row.id_status === 1 && (
                                                        <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                                    )}
                                                    {row.id_status === 2 && (
                                                        <UploadFileIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />
                                                    )}
                                                    {(row.id_status === 3) && (
                                                        <UploadFileIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />
                                                    )}
                                                    &nbsp;
                                                    <Typography>
                                                        {row.id_status === 1
                                                            ? t("converter.inReview")
                                                            : row.id_status === 2
                                                            ? t("converter.toBeSynch")
                                                            : t("converter.synchronized")}
                                                    </Typography>
                                                </Stack>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    {row.id_status === 1 && <LightTooltip title={t("converter.tooltipChat")} position="top">
                                                        <IconButton
                                                            onClick={() =>
                                                                handleClickChat(
                                                                    row.id_status,
                                                                    row._id,
                                                                    `#${zeroPad(row.correlative, 5)}`
                                                                )
                                                            }
                                                            size="small"
                                                            color="primary"
                                                        >
                                                            <Box
                                                                component="img"
                                                                src={ChatIcon}
                                                                sx={{ width: 17 }}
                                                            />
                                                        </IconButton>
                                                    </LightTooltip>}
                                                    {row.id_status >= 2 && (
                                                        <IconButton
                                                            onClick={() =>
                                                                handleShowPrev(row._id, row.account_id._id)
                                                            }
                                                            size="small"
                                                            color="primary"
                                                        >
                                                            {row.id_status === 3 ? <DownloadIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                                                        </IconButton>
                                                    )}
                                                </Stack>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <StyledTableRowB>
                                        <TablePagination
                                            rowsPerPageOptions={[
                                                10,
                                                20,
                                                30,
                                                { label: t("miscellaneous.all"), value: -1 },
                                            ]}
                                            colSpan={12}
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            labelRowsPerPage={t("dashboard.rowsPerPage")}
                                            labelDisplayedRows={(page) =>
                                                `${page.from}-${page.to === -1 ? page.count : page.to} ${t(
                                                    "team.unlinkText2"
                                                )} ${page.count}`
                                            }
                                        />
                                    </StyledTableRowB>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default IncomeTable;
