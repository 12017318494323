import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import conversionService from "../../../services/conversion";
import companyService from "../../../services/company";

import {
    Autocomplete,
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Popper,
    Select,
    Stack,
    styled,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";

import XeroLogo from "../../../assets/xero.svg";
import QuickBooksLogo from "../../../assets/quickbooks.svg";
import SyncIcon from "@mui/icons-material/Sync";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import WarningIcon from "@mui/icons-material/WarningRounded";
import EditIcon from "@mui/icons-material/Edit";

import LightTooltip from "../../ui/LightTooltip";
import InvoicesSyncTable from "./InvoicesSyncTable";
import InvoiceSlider from "./InvoiceSlider";
import SimpleDialog from "../../ui/SimpleDialog";
import FormDialog from "../../ui/FormDialog";
import EditContactChecks from "../checks/EditContactChecks";
import moment from "moment";

const checkIconDown = (props) => {
    if (props.className.includes("MuiSelect-iconOpen")) {
        return (
            <PlayArrowIcon
                sx={{
                    position: "absolute",
                    transform: "rotate(270deg)",
                    color: "#131F3E",
                    right: ".5rem",
                    cursor: "pointer",
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            />
        );
    }
    return (
        <PlayArrowIcon
            sx={{
                position: "absolute",
                transform: "rotate(90deg)",
                color: "#131F3E",
                right: ".5rem",
                cursor: "pointer",
                zIndex: 0,
                pointerEvents: "none",
            }}
        />
    );
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const greyBoxStyles = {
    backgroundColor: "#F8F8F9",
    px: 3,
    py: 2,
    borderRadius: 4,
    mt: 2,
};

const TabProps = {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 300,
    mr: 3,
    backgroundColor: "#f6f4fd",
    borderRadius: 3,
    minHeight: 35,
    height: 35,
    color: "#131F3E !important",
    "&.Mui-selected": {
        backgroundColor: "#c9bdf2",
        fontWeight: 600,
        borderRadius: 3,
    },
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
    [`& .${autocompleteClasses.paper}`]: {
        borderRadius: "15px",
    },
});

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const InvoicesSync = (props) => {
    const {
        setIsLoading,
        handleGoBack,
        downloadInv,
        invoicesData,
        returnInvoiceModal,
        setOpenPreview,
        setFileData,
        handleHistSync,
        setBasePreviewData,
        setOpenHeavy,
        replaceInvBill,
        conversionType,
        setTexto,
        setAlertType,
        setShow,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const filteredInvoicesData = JSON.parse(JSON.stringify(invoicesData));
    let filteredCurrentCompany = todos?.allComData.find((item) => item._id.toString() === todos?.companyID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const invoicesOriginal =
        filteredInvoicesData.invoices?.length > 0
            ? filteredInvoicesData.invoices.filter((item) => item.id_status !== 2 && item.id_status !== 5)
            : [{ ...filteredInvoicesData }];

    const [status, setStatus] = useState(0);
    const [invoiceData, setInvoiceData] = useState({});
    const [invoices, setInvoices] = useState([]);

    const [selectedAllAcc, setSelectedAllAcc] = useState("");
    const [selectedContact, setSelectedContact] = useState({});
    const [accountsXero, setAccountsXero] = useState([]);
    const [contactsXero, setContactsXero] = useState([]);
    const [xeroTaxRates, setXeroTaxRates] = useState([]);
    const [xeroItems, setXeroItems] = useState([]);
    const [contactsXeroOriginal, setContactsXeroOriginal] = useState([]);
    const [valueTab, setValueTab] = useState(0);
    const [showModalLeftSync, setShowModalLeftSync] = useState(false);
    const [contactXeroUpdated, setContactXeroUpdated] = useState("");
    const [extContactName, setExtContactName] = useState("");
    const [openModalEditContact, setOpenModalEditContact] = useState(false);
    const [edited, setEdited] = useState([]);
    const [rowsAux, setRowsAux] = useState([]);
    const [newRowDiff, setNewRowDiff] = useState(false);
    const [toBeCompleted, setToBeCompleted] = useState([]);
    const [toReview, setToReview] = useState([]);
    const [readyToSync, setReadyToSync] = useState([]);
    const [currency, setCurrency] = useState("");    const [editGlobal, setEditGlobal] = useState(false);

    const [allCurrencies, setAllCurrencies] = useState([]);
    const [systemCurrency, setSystemCurrency] = useState("");
    const [rate, setRate] = useState(invoicesData.exchange_rate ?? "");
    const [warningMod, setWarningMod] = useState(false);
    const [currentInvoicesOriginal, setCurrentInvoicesOriginal] = useState(invoicesOriginal);

    const calcSubtotal = (obj) => {
        return (
            parseFloat(obj.unit_price_xero ?? obj.formatted_unit_price ?? 0) *
            parseFloat(obj.quantity_xero ?? obj.formatted_quantity ?? 1)
        );
    };

    const calcTax = (obj) => {
        if (xeroTaxRates.length > 0 && "tax_type_xero" in obj && obj.tax_type_xero !== "") {
            const taxRate = xeroTaxRates.find((item) => item.value === obj.tax_type_xero).taxRate;
            return (taxRate * calcSubtotal(obj)) / 100;
        } else {
            return parseFloat(obj.tax_amount_xero ?? obj.tax_amount ?? 0);
        }
    };

    const calcTotal = (obj) => {
        return calcTax(obj) + calcSubtotal(obj);
    };

    // https://stackoverflow.com/a/36566052
    function editDistance(s1, s2) {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        var costs = [];
        for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i === 0) costs[j] = j;
                else {
                    if (j > 0) {
                        var newValue = costs[j - 1];
                        if (s1.charAt(i - 1) !== s2.charAt(j - 1))
                            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                        costs[j - 1] = lastValue;
                        lastValue = newValue;
                    }
                }
            }
            if (i > 0) costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    }

    function similarity(s1, s2) {
        if (s1 && s2) {
            if (s1.toLowerCase().includes(s2.toLowerCase()) || s2.toLowerCase().includes(s1.toLowerCase())) {
                return 1;
            }

            var longer = s1;
            var shorter = s2;
            if (s1.length < s2.length) {
                longer = s2;
                shorter = s1;
            }
            var longerLength = longer.length;
            if (longerLength === 0) {
                return 1.0;
            }
            return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
        } else {
            return 0;
        }
    }

    const isGreaterExt =
        rowsAux.filter((item) => !item.deleted).reduce((sum, obj) => sum + calcTotal(obj), 0) <
        invoiceData.formatted_invoice_total;

    const isEqual =
        invoiceData.formatted_invoice_total ===
        (edited.length > 0
            ? parseFloat(
                  rowsAux
                      .filter((item) => !item.deleted)
                      .reduce((sum, obj) => sum + calcTotal(obj), 0)
                      .toFixed(2)
              )
            : parseFloat(
                  invoiceData.line_items
                      ?.filter((item) => !item.deleted)
                      .reduce((sum, obj) => sum + calcTotal(obj), 0)
                      .toFixed(2)
              ));
              
    useEffect(() => {
        const found = currentInvoicesOriginal.some(
            (item) => item?.contact_account_code?.length !== 0 || item?.contact_tax_type?.length !== 0
        );
        let invoicesOriginalFinal = currentInvoicesOriginal.map((invoice) => {
            invoice.line_items = invoice.line_items.filter((line) => line.formatted_unit_price || line.unit_price_xero);
            return invoice;
        });
        if (found) {
            invoicesOriginalFinal = invoicesOriginalFinal.map((invoice) => {
                if (invoice.contact_account_code?.length === 1 || invoice.contact_tax_type?.length === 1) {
                    invoice.line_items = invoice.line_items.map((line) => {
                        if (
                            !line.account_code_xero &&
                            !line.account_code &&
                            invoice.contact_account_code.length === 1
                        ) {
                            line.account_code_xero = invoice.contact_account_code[0].AccountCode;
                        }
                        if (!line.tax_type_xero && !line.tax_type && invoice?.contact_tax_type?.length >= 1) {
                            line.tax_type_xero = invoice.contact_tax_type[0].TaxType;

                            if (xeroTaxRates.length > 0) {
                                const taxRate = xeroTaxRates.find(
                                    (item) => item.value === invoice.contact_tax_type[0].TaxType
                                ).taxRate;
                                line.tax_amount_xero = (taxRate * calcSubtotal(line)) / 100;
                            }
                        }
                        return line;
                    });
                }
                return invoice;
            });
        }
        if (toBeCompleted.length !== 0 || invoicesOriginalFinal.length === 1) {
            setInvoiceData({ ...invoicesOriginalFinal[0] });
        }

        setInvoices(invoicesOriginalFinal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xeroTaxRates, currentInvoicesOriginal]);

    useEffect(() => {
        const approximatelyEqual = (v1, v2, epsilon = 0.01) => Math.abs(v1 - v2) < epsilon;

        function validateFields(item, primaryField, secondaryField) {
            if (item[primaryField] !== undefined && item[primaryField] !== "") {
                return true;
            } else if (item[secondaryField] !== undefined && item[secondaryField] !== "") {
                return true;
            }
            return false;
        }

        // Listo para sincronizar
        const filter3 = invoices.filter((item) => {
            const lines = item?.line_items?.filter((item) => !item.deleted) ?? [];

            return (
                lines?.every((item) => validateFields(item, "account_code_xero", "account_code")) &&
                lines?.every((item) => validateFields(item, "quantity_xero", "quantity")) &&
                lines?.every((item) => validateFields(item, "unit_price_xero", "unit_price")) &&
                (item.contactXeroID !== undefined ||
                    (item.conversion_type === 1 ? item.customer_name !== undefined : item.vendor_name !== undefined)) &&
                (edited.length > 0
                    ? approximatelyEqual(
                          rowsAux.reduce((sum, obj) => sum + calcTotal(obj), 0),
                          item.formatted_invoice_total
                      )
                    : approximatelyEqual(
                          lines?.reduce((sum, obj) => sum + calcTotal(obj), 0),
                          item.formatted_invoice_total
                      ))
            );
        });

        // Por revisar
        const filter2 = invoices.filter((item) => {
            const lines = item?.line_items?.filter((item) => !item.deleted) ?? [];

            return (
                lines?.every((item) => validateFields(item, "account_code_xero", "account_code")) &&
                lines?.every((item) => validateFields(item, "quantity_xero", "quantity")) &&
                lines?.every((item) => validateFields(item, "unit_price_xero", "unit_price")) &&
                (item.contactXeroID !== undefined ||
                    (item.conversion_type === 1 ? item.customer_name !== undefined : item.vendor_name !== undefined)) &&
                (edited.length > 0
                    ? !approximatelyEqual(
                          rowsAux.reduce((sum, obj) => sum + calcTotal(obj), 0),
                          item.formatted_invoice_total
                      )
                    : !approximatelyEqual(
                          lines?.reduce((sum, obj) => sum + calcTotal(obj), 0),
                          item.formatted_invoice_total
                      ))
            );
        });

        const filter1 = invoices.filter((item) => {
            return !filter2.some((item2) => item2._id === item._id) && !filter3.some((item2) => item2._id === item._id);
        });

        // Revisar si hay cambios en general
        if (JSON.stringify(toBeCompleted) !== JSON.stringify(filter1)) {
            // Cambios de ids
            if (
                JSON.stringify(toBeCompleted.map((item) => item._id)) !==
                JSON.stringify(filter1.map((item) => item._id))
            ) {
                if (invoices.length > 1) {
                    if (valueTab === 0) {
                        if (filter1.length > 0) {
                            setInvoiceData({ ...filter1[0] });
                        } else {
                            setInvoiceData({});
                        }
                    }
                }
            }
            setToBeCompleted(filter1);
        }
        if (JSON.stringify(toReview) !== JSON.stringify(filter2)) {
            // Cambios de ids
            if (JSON.stringify(toReview.map((item) => item._id)) !== JSON.stringify(filter2.map((item) => item._id))) {
                if (invoices.length > 1) {
                    if (valueTab === 1) {
                        if (filter2.length > 0) {
                            setInvoiceData({ ...filter2[0] });
                        } else {
                            setInvoiceData({});
                        }
                    }
                }
            }
            setToReview(filter2);
        }
        if (JSON.stringify(readyToSync) !== JSON.stringify(filter3)) {
            if (
                JSON.stringify(readyToSync.map((item) => item._id)) !== JSON.stringify(filter3.map((item) => item._id))
            ) {
                if (invoices.length > 1) {
                    if (valueTab === 2) {
                        if (filter3.length > 0) {
                            setInvoiceData({ ...filter3[0] });
                        } else {
                            setInvoiceData({});
                        }
                    }
                }
            }
            setReadyToSync(filter3);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices]); // AÃ±adir valueTab como dependencia

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            await conversionService
                .getChartOfAccounts({
                    company_id: todos.companyID,
                    type: conversionType === 1 ? 3 : 2,
                })
                .then((data) => {
                    setAccountsXero(data.map((item) => ({ value: item.Code, name: item.Name })));
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getContacts({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    setContactsXeroOriginal(
                        data.map((item) => ({
                            value: item.ContactID,
                            name: item.Name,
                            contact_tax_number: item.contact_tax_number,
                            idSys: item.contact_id_system,
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getTaxRates({
                    company_id: todos.companyID,
                    type: conversionType === 1 ? 3 : 2, // 2 o 3
                })
                .then((data) => {
                    setXeroTaxRates(
                        data.map((item) => ({
                            value: item.TaxType,
                            name: `${item.Name} - ${item.EffectiveRate}%`,
                            taxRate: item.EffectiveRate,
                            EffectiveRate: item.EffectiveRate,
                            isZeroTax: item.TaxComponents[0].Name === "No Tax",
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getXeroItems({
                    company_id: todos.companyID,
                    type: conversionType === 1 ? 1 : 2,
                })
                .then((data) => {
                    setXeroItems(data.map((item) => ({ value: item.ItemID, name: item.Name })));
                })
                .catch((err) => {
                    console.log(err);
                });

            let companyCurrency = "";
            await companyService
                .getCompanyDetails(todos.companyID)
                .then((data) => {
                    setSystemCurrency(data.system_currency);
                    companyCurrency = data.system_currency;
                })
                .catch((error) => {
                    console.log(error);
                });

            await conversionService
                .getXeroCurrencies({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    if (data.length > 0) {
                        const currencyMap = data.map((item) => ({
                            id: item.Code,
                            name: item.Description,
                            setup: item.setup,
                        }));
                        setAllCurrencies(currencyMap);
                        const findCurrency = currencyMap.find((currency) => currency.id === companyCurrency);
                        setCurrency(
                            invoicesData.currency_iso_code ? invoicesData.currency_iso_code : findCurrency?.id ?? ""
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            setIsLoading(false);
        })();
    }, [conversionType, invoicesData.currency_iso_code, setIsLoading, todos.companyID]);

    // Función para formatear un RIF o RNC
    function formatTaxNumber(taxNumber) {
        if (!taxNumber) {
            return null;
        }

        taxNumber = taxNumber.toString();
        taxNumber = taxNumber.split("\n")[0];

        // Se reemplazan los caracteres no permitidos por vacío
        // var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'F', 'J', 'V', 'E', 'P', 'G'];
        var chars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        taxNumber = taxNumber.replace(new RegExp(`[^${chars.join("")}]`, "g"), "");

        return taxNumber;
    }

    useEffect(() => {
        if (contactsXeroOriginal.length > 0) {
            let newContacts = [];
            const contactName = invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name;
            const selectedContactNew = {
                value: "first",
                name: contactName,
            };
            const findContactSim = contactsXeroOriginal.find((item) => item.name === contactName);
            let found = false;

            // Verificamos si es de estos paises para buscar por Tax number
            if (
                invoiceData.company_country_name === "Venezuela" ||
                invoiceData.company_country_name === "República Dominicana"
            ) {
                if (invoiceData.formatted_customer_tax_id || invoiceData.formatted_vendor_tax_id) {
                    const formattedCustomerTaxId =
                        invoiceData.conversion_type === 1
                            ? formatTaxNumber(invoiceData.formatted_customer_tax_id)
                            : formatTaxNumber(invoiceData.formatted_vendor_tax_id);

                    found = contactsXeroOriginal.find(
                        (x) => formatTaxNumber(x.contact_tax_number) === formattedCustomerTaxId
                    );
                }
            }

            if (found) {
                newContacts = [...contactsXeroOriginal];
                selectedContactNew.name = found.name;
                selectedContactNew.value = found.value;
            } else {
                if (findContactSim) {
                    newContacts = [...contactsXeroOriginal];
                    selectedContactNew.value = contactsXeroOriginal.find((item) => item.name === contactName).value;
                } else {
                    newContacts = [
                        {
                            value: "first",
                            name:
                                invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name,
                        },
                        ...contactsXeroOriginal,
                    ];
                    // Si no se consigue el contacto exacto, se busca una coincidencia aproximada
                    const foundSimilar = contactsXeroOriginal.find(
                        (item) => similarity(item.name, contactName) >= 0.85
                    );
                    if (foundSimilar) {
                        selectedContactNew.value = foundSimilar.value;
                        selectedContactNew.name = foundSimilar.name;
                    }
                }
            }

            setContactsXero(newContacts);
            setSelectedContact(selectedContactNew);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactsXeroOriginal, invoiceData.conversion_type, invoiceData.customer_name, invoiceData.vendor_name]);

    const handleChangeSelectAcc = (event) => {
        setSelectedAllAcc(event.target.value);
        setInvoices(
            invoices.map((aux) => ({
                ...aux,
                line_items: aux.line_items.map((item) => ({
                    ...item,
                    account_code: event.target.value,
                    account_code_xero: event.target.value,
                })),
            }))
        );
        setInvoiceData({
            ...invoiceData,
            line_items: invoiceData.line_items.map((aux) => ({
                ...aux,
                account_code: event.target.value,
                account_code_xero: event.target.value,
            })),
            global_acc_code: event.target.value,
        });
    };

    const handleChangeSelectContact = async (newValue) => {
        setIsLoading(true);
        setSelectedContact(newValue);

        if (newValue !== null) {
            setInvoices(
                invoices.map((aux) => {
                    if (aux._id === invoiceData._id) {
                        aux.contactXeroID = newValue.value;
                        if (invoiceData.conversion_type === 1) {
                            aux.customer_name = newValue.name;
                        } else {
                            aux.vendor_name = newValue.name;
                        }
                    }
                    return aux;
                })
            );

            const newContactID = contactsXero.find((item) => item.value === newValue.value).idSys;
            const params = {
                company_id: todos.companyID,
                contact_id_system: newContactID,
                invoiceType: invoiceData.conversion_type
            };

            await conversionService.getLastInvoicesFromContactArray(params).then((response) => {
                if (response) {
                    const invoicesOriginalFinal = currentInvoicesOriginal.map((invoice) => {
                        if(invoice._id === invoiceData._id){
                            invoice.contact_account_code = response.contact_account_code;
                            invoice.contact_tax_type = response.contact_tax_type;
                        }
                       
                        return invoice;
                    });

                    setCurrentInvoicesOriginal(invoicesOriginalFinal);
                }
            });
        }

        setIsLoading(false);
    };

    const handleCloseSyncModal = () => setShowModalLeftSync(false);

    //Funcion de sincronizacion
    const syncInvoices = async () => {
        let readyToSyncAmounts = readyToSync.concat(toReview);

        if (readyToSyncAmounts.length !== currentInvoicesOriginal.length) {
            setShowModalLeftSync(true);
        } else {
            setIsLoading(true);

            // Sincronizamos las facturas
            const params = {
                invoices: readyToSyncAmounts.map((item) => ({
                    _id: item._id,
                    ContactXeroID: item.contactXeroID,
                    ContactXeroName: item.conversion_type === 1 ? item.customer_name : item.vendor_name,
                    line_items: JSON.stringify(item.line_items),
                })),
                currency: currency,
                currency_rate: rate === "" ? "1" : rate,
            };

            if (invoicesData?.invoices?.length > 0) {
                params.grouped_invoice_id = invoicesData._id;
            }

            await conversionService
                .syncInvoices(params)
                .then(async (response) => {
                    if (response.data.status === 203) {
                        setIsLoading(false);
                        endSync();
                        setOpenHeavy(true);
                    } else {
                        let params = { sync_id: response.data };
                        await handleHistSync(params);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            setIsLoading(false);
        }
    };

    const handleClickEditContact = (event, name) => {
        event.stopPropagation();
        setContactXeroUpdated(name);
        setExtContactName(invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name);
        setOpenModalEditContact(true);
    };

    const endSync = () => {
        setStatus(0);
        handleGoBack();
    };

    const handleCloseDialog = (event) => {
        event.preventDefault();
        setOpenModalEditContact(false);
        setContactXeroUpdated("");
    };

    const handleSync = async () => {
        setIsLoading(true);
        // Sincronizamos las facturas
        const params = {
            invoices: readyToSync.map((item) => ({
                _id: item._id,
                ContactXeroID: item.contactXeroID,
                ContactXeroName: item.conversion_type === 1 ? item.customer_name : item.vendor_name,
                line_items: JSON.stringify(item.line_items),
            })),
            currency: currency,
            currency_rate: rate === "" ? "1" : rate,
        };

        await conversionService
            .syncInvoices(params)
            .then(async (response) => {
                await handleHistSync(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
        setShowModalLeftSync(false);
    };

    const handleInvoice = (invoice) => {
        if (invoiceData._id !== invoice._id) {
            setInvoiceData(invoice);
            setContactsXero([
                { name: invoice.conversion_type === 1 ? invoice.customer_name : invoice.vendor_name, value: "first" },
                ...contactsXeroOriginal,
            ]);
            setSelectedContact({
                value: invoice.contactXeroID ?? "first",
                name: invoice.conversion_type === 1 ? invoice.customer_name : invoice.vendor_name,
            });
        }
    };

    const handleChangeTab = async (event, newValue) => {
        setValueTab(newValue);

        let newData = {};

        if (newValue === 0) {
            newData = toBeCompleted[0] ?? {};
        }
        if (newValue === 1) {
            newData = toReview[0] ?? {};
        }
        if (newValue === 2) {
            newData = readyToSync[0] ?? {};
        }

        setInvoiceData(newData);
    };

    const handleConfirmUpdateXeroContact = () => {
        const finalRows = invoices.map((aux) => {
            if (aux._id === invoiceData._id) {
                return {
                    ...aux,
                    customer_name: contactXeroUpdated,
                };
            } else {
                return aux;
            }
        });
        setInvoices(finalRows);
        setContactsXero(
            contactsXero.map((item) => {
                if (item.value === "first") {
                    item.name = contactXeroUpdated;
                    item.new = true;
                }
                return item;
            })
        );
        setSelectedContact({
            value: "first",
            name: contactXeroUpdated,
        });
        setOpenModalEditContact(false);
    };

    // const handleOpenWarnings = () => setWarningMod(true);

    const handleCloseWarnings = () => setWarningMod(false);

    const dialogContactButtons = (
        <Grid container justifyContent="space-evenly">
            <Grid item>
                <Button
                    size="large"
                    disableElevation
                    variant="outlined"
                    onClick={handleCloseDialog}
                    color="primary"
                    sx={{
                        borderRadius: 3,
                        fontSize: 15,
                        px: 4,
                        py: 1.3,
                    }}
                >
                    {t("team.cancel")}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    size="large"
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmUpdateXeroContact}
                    sx={{
                        borderRadius: 3,
                        fontSize: 15,
                        px: 4,
                        py: 1.3,
                    }}
                    disabled={!contactXeroUpdated || contactXeroUpdated === ""}
                >
                    {t("subscription.confirm")}
                </Button>
            </Grid>
        </Grid>
    );

    const handleChangeCurrency = async (event) => {
        setCurrency(event.target.value);
        if (event.target.value === systemCurrency) {
            setRate("");
        } else {
            await conversionService
                .getXECurrencyRate({
                    from: event.target.value,
                    to: systemCurrency,
                    date: moment.utc(invoiceData.formatted_invoice_date).format("YYYY-MM-DD"),
                })
                .then((data) => {
                    setRate(data.amount.toString());
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleChangeRate = async (event) => {
        setRate(event.target.value);
    };

    const formatAmount = (amount) => {
        return parseFloat(amount).toLocaleString(todos.amountFormat, { maximumFractionDigits: 2 });
    };

    const printSubtotalCalc = (data) => {
        return formatAmount(
            data?.filter((item) => !item.deleted).reduce((sum, obj) => sum + calcSubtotal(obj), 0) ?? 0
        );
    };

    const printTaxCalc = (data) => {
        return formatAmount(data?.filter((item) => !item.deleted).reduce((sum, obj) => sum + calcTax(obj), 0) ?? 0);
    };

    const printTotalCalc = (data) => {
        return formatAmount(data?.filter((item) => !item.deleted).reduce((sum, obj) => sum + calcTotal(obj), 0) ?? 0);
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: status === 0 ? "#F6F4FD" : status === 1 || status === 2 ? "#DBF4E5" : "#FFD8D5",
                    px: 3,
                    py: 2,
                    borderRadius: 4,
                    mt: 2,
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    {status === 0 ? (
                        <>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h3">
                                    {replaceInvBill(
                                        t(
                                            filteredCurrentCompany?.system_integration === 1
                                                ? "converter.syncXeroInvoice"
                                                : "converter.syncQBOInvoice"
                                        )
                                    )}
                                </Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        height: filteredCurrentCompany?.system_integration === 1 ? 20 : 40,
                                        width: filteredCurrentCompany?.system_integration === 1 ? 20 : 40,
                                    }}
                                    src={filteredCurrentCompany?.system_integration === 1 ? XeroLogo : QuickBooksLogo}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" endIcon={<DownloadIcon />} onClick={downloadInv}>
                                    {t("payment.download")}
                                </Button>
                                <LightTooltip
                                    title={
                                        readyToSync.length + toReview.length === 0 ? t("converter.disabledSync") : ""
                                    }
                                >
                                    <span>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            endIcon={<SyncIcon />}
                                            onClick={syncInvoices}
                                            disabled={readyToSync.length + toReview.length === 0 ? true : false}
                                        >
                                            {t("converter.sync")}
                                        </Button>
                                    </span>
                                </LightTooltip>
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h3">
                                    {status === 1 || status === 2
                                        ? t("converter.checksSyncSucc")
                                        : t("converter.bannerErr")}
                                </Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        height: filteredCurrentCompany?.system_integration === 1 ? 20 : 40,
                                        width: filteredCurrentCompany?.system_integration === 1 ? 20 : 40,
                                    }}
                                    src={filteredCurrentCompany?.system_integration === 1 ? XeroLogo : QuickBooksLogo}
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={4}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{
                                        backgroundColor: status !== 3 ? "#E4F7EB" : "#FFE1E6",
                                        borderRadius: 10,
                                        px: 2,
                                        py: 1,
                                    }}
                                >
                                    {status === 1 ? (
                                        <CheckIcon color="success" />
                                    ) : status === 2 ? (
                                        <ErrorIcon color="warning" />
                                    ) : (
                                        <CloseIcon color="error" />
                                    )}
                                    <Typography>
                                        {status === 1
                                            ? t("converter.bannerCheck")
                                            : status === 2
                                            ? t("converter.bannerWarning")
                                            : t("converter.bannerError")}
                                    </Typography>
                                </Stack>
                                <Button variant="contained" disableElevation onClick={endSync}>
                                    {t("dialog.finally")}
                                </Button>
                            </Stack>
                        </>
                    )}
                </Stack>
                {currentInvoicesOriginal.length > 1 && (
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography>
                            <b>{currentInvoicesOriginal.length}</b> {replaceInvBill(t("converter.totalInvo"))}
                        </Typography>
                        <Box>|</Box>
                        <Typography sx={{ color: "#00943B", fontWeight: 600 }}>
                            {(readyToSync.length + toReview.length)}
                            {replaceInvBill(t("converter.invReadyToSync"))}
                        </Typography>
                    </Stack>
                )}
            </Box>
            <Box sx={greyBoxStyles}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h1">{replaceInvBill(t("converter.invSync"))}</Typography>
                    {currentInvoicesOriginal.length > 1 && (
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography>{replaceInvBill(t("converter.selectAccAllInv"))}:</Typography>
                            <FormControl variant="filled" sx={{ width: 300 }}>
                                <InputLabel
                                    id="simple-select-label-all"
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        color: "#131F3E",
                                    }}
                                >
                                    {t("accounts.accountNum")}
                                </InputLabel>
                                <Select
                                    labelId="simple-select-label-all"
                                    name="accXero"
                                    value={selectedAllAcc}
                                    onChange={handleChangeSelectAcc}
                                    IconComponent={(props) => checkIconDown(props)}
                                    size="small"
                                    MenuProps={MenuProps}
                                >
                                    {accountsXero.map((item) => (
                                        <MenuItem value={item.value} key={item.value}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <Button
                                variant="outlined"
                                sx={{ backgroundColor: "white", px: 4, py: 1 }}
                                startIcon={<FilterAltIcon />}
                            >
                                {t("dashboard.filters")}
                            </Button> */}
                        </Stack>
                    )}
                </Stack>
                {currentInvoicesOriginal.length > 1 && (
                    <Tabs
                        orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                        aria-label="Horizontal tabs"
                        sx={{
                            p: 1,
                            mt: 2,
                        }}
                        TabIndicatorProps={{
                            style: { display: "none" },
                        }}
                        value={valueTab}
                        onChange={handleChangeTab}
                    >
                        <Tab
                            label={
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography sx={{ fontWeight: valueTab === 0 ? 600 : 500, fontSize: 16 }}>
                                        {t("converter.toComplete")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "#4a22d4",
                                            color: "white",
                                            px: 1,
                                            py: 0.2,
                                            fontSize: 12,
                                        }}
                                    >
                                        {toBeCompleted.length}
                                    </Box>
                                </Stack>
                            }
                            {...a11yProps(0)}
                            sx={TabProps}
                        />
                        <Tab
                            label={
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography sx={{ fontWeight: valueTab === 1 ? 600 : 500, fontSize: 16 }}>
                                        {t("converter.toReview")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "#4a22d4",
                                            color: "white",
                                            px: 1,
                                            py: 0.2,
                                            fontSize: 12,
                                        }}
                                    >
                                        {toReview.length}
                                    </Box>
                                </Stack>
                            }
                            {...a11yProps(1)}
                            sx={TabProps}
                        />
                        <Tab
                            label={
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography sx={{ fontWeight: valueTab === 2 ? 600 : 500, fontSize: 16 }}>
                                        {t("converter.toBeSynch")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderRadius: 4,
                                            backgroundColor: "#4a22d4",
                                            color: "white",
                                            px: 1,
                                            py: 0.2,
                                            fontSize: 12,
                                        }}
                                    >
                                        {readyToSync.length}
                                    </Box>
                                </Stack>
                            }
                            {...a11yProps(2)}
                            sx={TabProps}
                        />
                    </Tabs>
                )}
                <TabPanel value={valueTab} index={0}>
                    <InvoiceSlider
                        invoices={currentInvoicesOriginal.length === 1 ? currentInvoicesOriginal : toBeCompleted}
                        invoiceData={invoiceData}
                        handleInvoice={handleInvoice}
                        icon={
                            readyToSync.length === 1 && readyToSync.find((e) => e._id === invoiceData._id)
                                ? "check"
                                : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id)
                                ? "warning"
                                : "alert"
                        }
                    />
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                    <InvoiceSlider
                        invoices={toReview}
                        invoiceData={invoiceData}
                        handleInvoice={handleInvoice}
                        icon="warning"
                    />
                </TabPanel>
                <TabPanel value={valueTab} index={2}>
                    <InvoiceSlider
                        invoices={readyToSync}
                        invoiceData={invoiceData}
                        handleInvoice={handleInvoice}
                        icon="check"
                    />
                </TabPanel>
            </Box>
            <Box sx={greyBoxStyles}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item={12} md={5}>
                        <Stack direction="row" alignItems="center" spacing={0.5} mb={0}>
                            <Typography variant="h1">
                                {replaceInvBill(t("payment.invoice"))}{" "}
                                <span style={{ color: "#4a22d4" }}>{invoiceData.invoice_id}</span>
                            </Typography>
                            {Object.keys(invoiceData).length > 0 && (
                                <LightTooltip title={t("converter.tooltipReturnInv")}>
                                    <>
                                        <IconButton
                                            edge="end"
                                            aria-label="archive"
                                            onClick={() => returnInvoiceModal(invoiceData._id)}
                                            sx={{ p: 0.3 }}
                                        >
                                            <DeleteIcon sx={{ fontSize: "22px" }} color="primary" />
                                        </IconButton>
                                    </>
                                </LightTooltip>
                            )}
                            {/* <Button
                                variant="outlined"
                                endIcon={<WarningIcon htmlColor="#ffb23f" />}
                                onClick={handleOpenWarnings}
                            >
                                {t("converter.warnings")}
                            </Button> */}
                        </Stack>
                        <Typography sx={{ width: 350 }} gutterBottom mb={3}>
                            <b>{t("converter.contactExt")}:</b>{" "}
                            {invoiceData.conversion_type === 1 ? invoiceData.customer_name : invoiceData.vendor_name}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Autocomplete
                                PopperComponent={StyledPopper}
                                sx={{ minWidth: "33%" }}
                                options={contactsXero}
                                getOptionLabel={(option) => (option.name ? option.name : "")}
                                value={selectedContact}
                                onChange={(event, newValue) => handleChangeSelectContact(newValue)}
                                renderOption={(props, option, { index }) => {
                                    const { key, ...optionProps } = props;
                                    if (option.value === "first") {
                                        return (
                                            <Box
                                                key={key + index}
                                                component="li"
                                                {...optionProps}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <Stack direction="column">
                                                        <Typography fontWeight={600} color="primary">
                                                            {t("converter.edit")} {option.name}
                                                        </Typography>
                                                        <Typography fontSize={11} lineHeight={1} color="#131f3e">
                                                            {t("converter.editContactMsg")}
                                                        </Typography>
                                                    </Stack>
                                                    <IconButton
                                                        onClick={(event) => handleClickEditContact(event, option.name)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Stack>
                                            </Box>
                                        );
                                    } else {
                                        return (
                                            <Box
                                                key={key + index}
                                                component="li"
                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                {...optionProps}
                                            >
                                                <Typography color="#131f3e">{option.name}</Typography>
                                            </Box>
                                        );
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={
                                            <Typography fontWeight={600} color="#131F3E">
                                                {invoiceData.contactXeroID === "first"
                                                    ? t("converter.createContact")
                                                    : t("converter.chooseContact")}
                                            </Typography>
                                        }
                                        variant="filled"
                                        size="small"
                                    />
                                )}
                                popupIcon={
                                    <PlayArrowIcon
                                        sx={{
                                            transform: "rotate(90deg)",
                                            color: "#131F3E",
                                            right: ".5rem",
                                            cursor: "pointer",
                                            zIndex: 0,
                                            pointerEvents: "none",
                                        }}
                                    />
                                }
                                noOptionsText={
                                    <Box sx={{ bgcolor: "#e8e9ec", p: 0.8, borderRadius: 1 }}>
                                        <Typography fontWeight={600} fontSize={13} mt={0.5} color="#131f3e">
                                            {t("converter.noContact")}
                                        </Typography>
                                    </Box>
                                }
                            />
                            <LightTooltip
                                title={
                                    contactsXeroOriginal.find(
                                        (item) =>
                                            item.name ===
                                            (invoiceData.conversion_type === 1
                                                ? invoiceData.customer_name
                                                : invoiceData.vendor_name)
                                    ) &&
                                    (invoiceData.conversion_type === 1
                                        ? invoiceData.customer_name
                                        : invoiceData.vendor_name) === selectedContact?.name
                                        ? t("converter.tooltipSyncMatch")
                                        : contactsXero.find(
                                              (item) =>
                                                  similarity(
                                                      item.name,
                                                      invoiceData.conversion_type === 1
                                                          ? invoiceData.customer_name
                                                          : invoiceData.vendor_name
                                                  ) >= 0.85
                                          )
                                        ? t("converter.tooltipSyncAlmostMatch")
                                        : contactsXeroOriginal.find((item) => item.value === selectedContact?.value) ||
                                          contactsXero.find((item) => item.value === selectedContact?.value && item.new)
                                        ? t("converter.tooltipNewContact")
                                        : t("converter.tooltipSyncNotMatch")
                                }
                            >
                                {contactsXeroOriginal.find(
                                    (item) =>
                                        item.name ===
                                        (invoiceData.conversion_type === 1
                                            ? invoiceData.customer_name
                                            : invoiceData.vendor_name)
                                ) ||
                                contactsXeroOriginal.find((item) => item.value === selectedContact?.value) ||
                                contactsXero.find((item) => item.value === selectedContact?.value && item.new) ? (
                                    <CheckIcon
                                        color={
                                            contactsXeroOriginal.find(
                                                (item) =>
                                                    item.name ===
                                                    (invoiceData.conversion_type === 1
                                                        ? invoiceData.customer_name
                                                        : invoiceData.vendor_name)
                                            ) &&
                                            (invoiceData.conversion_type === 1
                                                ? invoiceData.customer_name
                                                : invoiceData.vendor_name) === selectedContact?.name
                                                ? "success"
                                                : "primary"
                                        }
                                    />
                                ) : contactsXero.find(
                                      (item) =>
                                          similarity(
                                              item.name,
                                              invoiceData.conversion_type === 1
                                                  ? invoiceData.customer_name
                                                  : invoiceData.vendor_name
                                          ) >= 0.85
                                  ) ? (
                                    <WarningIcon htmlColor="#ffb23f" />
                                ) : (
                                    <WarningIcon color="primary" />
                                )}
                            </LightTooltip>
                            <FormControl variant="filled" fullWidth size="small">
                                <InputLabel id={"customized-base-select-label"} sx={{ color: "#131f3e" }}>
                                    {t("dashboard.currency")}
                                </InputLabel>
                                <Select
                                    labelId={"customized-base-select-label"}
                                    id={"customized-base-select"}
                                    value={currency}
                                    onChange={handleChangeCurrency}
                                    MenuProps={MenuProps}
                                    IconComponent={(props) => {
                                        if (props.className.includes("MuiSelect-iconOpen")) {
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(270deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }
                                        return (
                                            <PlayArrowIcon
                                                sx={{
                                                    position: "absolute",
                                                    transform: "rotate(90deg)",
                                                    color: "#131F3E",
                                                    right: ".5rem",
                                                    cursor: "pointer",
                                                    zIndex: 0,
                                                    pointerEvents: "none",
                                                }}
                                            />
                                        );
                                    }}
                                >
                                    <Typography variant="h2" pl={1} gutterBottom>
                                        {t("converter.kiiperSug")}
                                    </Typography>
                                    {allCurrencies.filter((item) => item.setup).length === 0 ? (
                                        <MenuItem disabled value="">
                                            <em>{t("converter.noCurreConf")}</em>
                                        </MenuItem>
                                    ) : (
                                        allCurrencies
                                            .filter((item) => item.setup)
                                            .map((val) => (
                                                <MenuItem key={val.id} value={val.id}>
                                                    {val.name}
                                                </MenuItem>
                                            ))
                                    )}
                                    <Divider />
                                    <Typography variant="h2" pl={1} gutterBottom>
                                        {t("converter.otherCoins")}
                                    </Typography>
                                    {allCurrencies
                                        .filter((item) => !item.setup)
                                        .map((val) => (
                                            <MenuItem key={val.id} value={val.id}>
                                                {val.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <CustomTextField
                                fullWidth
                                size="small"
                                label={t("converter.rate")}
                                name="rate"
                                onChange={handleChangeRate}
                                value={rate !== 1 ? parseFloat(rate).toFixed(4) : rate}
                                variant="filled"
                                inputProps={{
                                    fontStyle: "normal",
                                }}
                                type="text"
                                disabled={rate === ""}
                            />
                        </Stack>
                    </Grid>
                    <Grid item={12} md={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} alignItems="center" display="flex" justifyContent="end">
                                <Typography variant="h3" textAlign="end">
                                    {t("converter.extracted")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.subtotalExt")}</Typography>
                                    <Typography>$ {formatAmount(invoiceData.formatted_sub_total ?? 0)}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.totalImpExt")}</Typography>
                                    <Typography>$ {formatAmount(invoiceData.formatted_total_tax ?? 0)}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor:
                                            valueTab === 2 ||
                                            (isEqual && readyToSync.find((e) => e._id === invoiceData._id))
                                                ? "#DBF4E5"
                                                : valueTab === 1 ||
                                                  (!isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id))
                                                ? "#FFF1DB"
                                                : "#FFD8DE",
                                        p: 2,
                                        borderRadius: 3,
                                    }}
                                >
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="h3">{t("converter.totalExt")}</Typography>
                                        <LightTooltip
                                            title={
                                                readyToSync.length > 0 &&
                                                readyToSync.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.readySyncLines")
                                                    : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.amountLines")
                                                    : t("converter.pendingLines")
                                            }
                                        >
                                            <InfoIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="primary"
                                            />
                                        </LightTooltip>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography>
                                            $ {formatAmount(invoiceData.formatted_invoice_total ?? 0)}
                                        </Typography>
                                        {readyToSync.length > 0 &&
                                        readyToSync.find((e) => e._id === invoiceData._id) ? (
                                            <CheckIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="success"
                                            />
                                        ) : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id) ? (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"warning"}
                                            />
                                        ) : (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"error"}
                                            />
                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3} alignItems="center" display="flex" justifyContent="end">
                                <Typography variant="h3" textAlign="end">
                                    {t("converter.calculated")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.subtotalCalc")}</Typography>
                                    <Typography>
                                        {`$ ${printSubtotalCalc(
                                            edited.length === 0 ? invoiceData.line_items : rowsAux
                                        )}`}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "white", p: 2, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">{t("converter.totalImpCalc")}</Typography>
                                    <Typography>
                                        {`$ ${printTaxCalc(edited.length === 0 ? invoiceData.line_items : rowsAux)}`}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor:
                                            valueTab === 2 ||
                                            (isEqual && readyToSync.find((e) => e._id === invoiceData._id))
                                                ? "#DBF4E5"
                                                : valueTab === 1 ||
                                                  (!isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id))
                                                ? "#FFF1DB"
                                                : "#FFD8DE",
                                        p: 2,
                                        borderRadius: 3,
                                    }}
                                >
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="h3">{t("converter.totalCalc")}</Typography>
                                        <LightTooltip
                                            title={
                                                isEqual && readyToSync.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.readySyncLines")
                                                    : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id)
                                                    ? t("converter.amountLines")
                                                    : t("converter.pendingLines")
                                            }
                                        >
                                            <InfoIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="primary"
                                            />
                                        </LightTooltip>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography>{`$ ${printTotalCalc(rowsAux)}`}</Typography>
                                        {readyToSync.length > 0 &&
                                        readyToSync.find((e) => e._id === invoiceData._id) ? (
                                            <CheckIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color="success"
                                            />
                                        ) : !isEqual && !toBeCompleted.find((e) => e._id === invoiceData._id) ? (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"warning"}
                                            />
                                        ) : (
                                            <ErrorIcon
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                                color={"error"}
                                            />
                                        )}
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <InvoicesSyncTable
                invoiceData={invoiceData}
                accountsXero={accountsXero}
                isGreaterExt={isGreaterExt}
                setOpenPreview={setOpenPreview}
                setIsLoading={setIsLoading}
                setFileData={setFileData}
                xeroTaxRates={xeroTaxRates}
                xeroItems={xeroItems}
                setInvoiceData={setInvoiceData}
                setInvoices={setInvoices}
                invoices={invoices}
                setBasePreviewData={setBasePreviewData}
                rowsAux={rowsAux}
                setRowsAux={setRowsAux}
                edited={edited}
                setEdited={setEdited}
                setNewRowDiff={setNewRowDiff}
                newRowDiff={newRowDiff}
                calcTotal={calcTotal}
                replaceInvBill={replaceInvBill}
                formatAmount={formatAmount}
                calcSubtotal={calcSubtotal}
                setTexto={setTexto}
                setAlertType={setAlertType}
                setShow={setShow}
                editGlobal={editGlobal}
                setEditGlobal={setEditGlobal}
            />
            <SimpleDialog
                open={showModalLeftSync}
                handleClose={handleCloseSyncModal}
                maxWidth="sm"
                content={
                    <Box sx={{ px: 4 }}>
                        <Stack direction="row" alignItems="center" sx={{ pb: 3 }} spacing={2}>
                            <WarningIcon sx={{ color: "#FFB23F", fontSize: 16 }} />
                            <Typography variant="h2" sx={{ color: "#FFB23F" }}>
                                {t("dialog.dialogSyncLeftTitle")}
                            </Typography>
                        </Stack>
                        <Typography sx={{ pb: 2 }}>{t("dialog.dialogSyncLeftText")}</Typography>
                        <Typography sx={{ pb: 3 }}>{t("dialog.dialogSyncLeftText2")}</Typography>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                            <Button onClick={handleCloseSyncModal}>{t("register.back")}</Button>
                            <Button variant="contained" disableElevation onClick={handleSync}>
                                {t("dialog.continue")}
                            </Button>
                        </Stack>
                    </Box>
                }
            />
            <FormDialog
                open={openModalEditContact}
                handleClose={handleCloseDialog}
                maxWidth="sm"
                title={
                    <Stack direction="row" justifyContent="center" spacing={1.5} alignItems="center">
                        <InfoIcon fontSize="small" sx={{ color: "#4A22D4" }} />
                        <Typography fontSize={24} color="primary" fontWeight={600} textAlign="center">
                            {t("converter.dialogContactXero")}
                        </Typography>
                    </Stack>
                }
                content={
                    <EditContactChecks
                        extContactName={extContactName}
                        contactXeroUpdated={contactXeroUpdated}
                        setContactXeroUpdated={setContactXeroUpdated}
                    />
                }
                actions={dialogContactButtons}
                align="center"
            />
            <SimpleDialog
                open={warningMod}
                handleClose={handleCloseWarnings}
                maxWidth="md"
                content={
                    <Box sx={{ px: 4 }}>
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ pb: 3 }} spacing={2}>
                            <WarningIcon htmlColor="#ffb23f" />
                            <Typography variant="h1" sx={{ color: "#FFB23F" }}>
                                {t("converter.warnings")}
                            </Typography>
                        </Stack>
                        <Typography>{t("converter.warntitle1")}</Typography>
                        <Typography pb={2}>{t("converter.warntitle2")}</Typography>
                        <Typography color="primary" fontWeight={600}>
                            {t("converter.warntext1")}
                        </Typography>
                        <Typography pb={2}>{t("converter.warntext2")}</Typography>
                    </Box>
                }
            />
        </>
    );
};

export default InvoicesSync;
