import {
    Box,
    Button,
    ButtonGroup,
    InputAdornment,
    MenuItem,
    MenuList,
    Pagination,
    Paper,
    Popover,
    Skeleton,
    Stack,
    TextField,
    ToggleButton,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ToggleButtonGroup, { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";

import subscriptionService from "../../../services/subscription";
import invoiceService from "../../../services/invoices";

import FiltersButton from "./FiltersButton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { useTranslation } from "react-i18next";

import moment from "moment";

const rowsSkeleton = [];

const plans = [
    { id: 0, type: "Micro", pages: 20 },
    { id: 1, type: "Small", pages: 20 },
    { id: 2, type: "Small", pages: 50 },
    { id: 3, type: "Small", pages: 100 },
    { id: 4, type: "Small", pages: 200 },
    { id: 5, type: "Small", pages: 300 },
    { id: 6, type: "Small", pages: 400 },
    { id: 7, type: "Small", pages: 500 },
    { id: 8, type: "Medium", pages: 1000 },
    { id: 9, type: "Medium", pages: 1500 },
    { id: 10, type: "Medium", pages: 2000 },
    { id: 11, type: "Medium", pages: 2500 },
    { id: 12, type: "Enterprise", pages: 5000 },
    { id: 13, type: "Enterprise", pages: 10000 },
    { id: 14, type: "Enterprise", pages: 15000 },
    { id: 15, type: "Enterprise", pages: Infinity },
];

for (let i = 0; i < 5; i++) {
    rowsSkeleton.push(<Skeleton variant="text" animation="wave" height={150} />);
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        marginRight: theme.spacing(2),
        padding: "10px 20px",
        border: 0,
        borderRadius: 10,
        backgroundColor: "#F6F4FD !important",
        color: "#131f3e",
        "&:hover": {
            backgroundColor: "#EAE6FA !important",
        },
        [`&.Mui-selected`]: {
            backgroundColor: "#4A22D4 !important", // Customize this to any color you want
            color: theme.palette.common.white, // Text color when selected
            "&:hover": {
                backgroundColor: "#421EBE !important", // Change this for hover state if needed
            },
        },
    },
}));

const InvoicesList = (props) => {
    const { payments, setIsLoading } = props;
    const todos = useSelector((state) => state.value);
    const [t] = useTranslation("global");

    const [invoices, setInvoices] = useState([]);
    const [invoicesOG, setInvoicesOG] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [alignment, setAlignment] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [showDet, setShowDet] = useState([]);
    const [invoiceID, setInvoiceID] = useState(null);

    const handleAlignment = (event, newAlignment, index) => {
        setAlignment(
            alignment.map((item, index2) => {
                if (index === index2) {
                    item = newAlignment;
                }
                return item;
            })
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const optionsGroupSend = ["Factura digital", "Reporte detallado"];

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleToggleBtnGroup = (event, invoiceID) => {
        setAnchorEl(event.currentTarget);
        setInvoiceID(invoiceID);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        (async () => {
            setShowSkeleton(true);

            await subscriptionService
                .getInvoices({ franchise_id: todos.franchiseID })
                .then((response) => {
                    setInvoices(response);
                    setInvoicesOG(response);
                    setAlignment(response.map(() => ""));
                    setShowDet(response.map(() => ({ show: false, plan: "", companies: [] })));
                })
                .catch((err) => console.log(err));

            setShowSkeleton(false);
        })();
    }, [todos.franchiseID]);

    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);

        const filterInv = invoicesOG.filter((item) =>
            item.invoice_number.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setInvoices(filterInv);
    };

    const handleMenuItemClickBtnGroup = () => {
        setAnchorEl(null);
        handleDownloadInv();
    };

    const handleDownHist = async () => {
        setIsLoading(true);
        const params = {
            franchise_id: todos.franchiseID,
            file_name: "Invoices_Hist",
        };

        await invoiceService
            .downloadInvoiceReportByFranchise(params)
            .then((response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => console.log(err));

        setIsLoading(false);
    };

    const handleDownloadInv = async () => {
        setIsLoading(true);
        const params = {
            invoice_id: invoiceID,
            file_name: "Invoice",
        };

        await invoiceService
            .downloadInvoiceReport(params)
            .then((response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => console.log(err));

        setIsLoading(false);
    };

    const showPlansDet = (planDet, companies, index) => {
        setShowDet(
            showDet.map((item, index2) => {
                if (index2 === index) {
                    item = { show: planDet === item.plan ? !item.show : true, plan: planDet, companies: companies };
                }
                return item;
            })
        );
    };

    return (
        <Paper
            elevation={0}
            square={false}
            sx={{
                borderRadius: 2.5,
                minHeight: "calc(100vh - 300px)",
                p: 4,
                justifyContent: "center",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" pb={2}>
                <Typography variant="h1">{t("payment.billing")}</Typography>
                <Button endIcon={<DownloadIcon />} variant="contained" onClick={handleDownHist}>
                    {t("payment.downloadHist")}
                </Button>
            </Stack>
            <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} mb={1}>
                <FiltersButton
                    franchise={todos.franchiseID}
                    company={todos.companyID}
                    rowsSkeleton={rowsSkeleton}
                    setRows={setInvoices}
                    payments={payments}
                />
                <TextField
                    id="input-with-icon-textfield"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="filled"
                    hiddenLabel
                    placeholder={t("team.search")}
                    value={searchValue}
                    onChange={handleChangeSearchValue}
                />
            </Stack>
            {showSkeleton
                ? rowsSkeleton.map((item) => item)
                : invoices.slice((page - 1) * 5, page * 5).map((item, index) => (
                    <Paper
                        elevation={0}
                        sx={{
                            border: `1px solid #6544DB`,
                            borderRadius: 2,
                            px: 3,
                            py: 1.5,
                            mb: 1,
                        }}
                        key={item._id}
                    >
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Typography variant="h1" color="primary">
                                    {t("payment.invoice")} {item.invoice_number}
                                </Typography>
                                <Typography>
                                    {moment.utc(item.since_date).format(todos.dateFormat)} -{" "}
                                    {moment.utc(item.end_date).format(todos.dateFormat)}
                                </Typography>
                                <Box
                                    sx={{
                                        borderRadius: 5,
                                        background: item.invoice_status === 1 ? "#34bd6a" : "#FF5E77",
                                        padding: "5px 15px",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: item.invoice_status === 1 ? "white" : "#4a22d4",
                                        }}
                                    >
                                        {item.invoice_status === 1 ? t("payment.paid") : t("payment.expired")}
                                    </Typography>
                                </Box>
                            </Stack>
                            <ButtonGroup
                                disableElevation
                                variant="outlined"
                                aria-label="split button"
                                color="inherit"
                            >
                                <Button color="primary">{t("payment.downloadInv")}</Button>
                                <Button
                                    aria-describedby={id}
                                    color="primary"
                                    onClick={(e) => handleToggleBtnGroup(e, item._id)}
                                >
                                    <PlayArrowIcon
                                        sx={{
                                            transform: "rotate(90deg)",
                                        }}
                                    />
                                </Button>
                            </ButtonGroup>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" pt={1}>
                            <StyledToggleButtonGroup
                                value={alignment[index]}
                                exclusive
                                onChange={(e, newAlignment) => handleAlignment(e, newAlignment, index)}
                            >
                                {Object.entries(item.plans_by_company).map((item) => (
                                    <ToggleButton
                                        key={item[0]}
                                        value={item[0]}
                                        onClick={() => showPlansDet(plans[item[0]], item[1], index)}
                                    >
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Box sx={{ textAlign: "left" }}>
                                                <Typography variant="h2" gutterBottom>
                                                    Plan {plans[item[0]].type}
                                                </Typography>
                                                <Typography>
                                                    <b>{item[1].length} tiers</b> {item[1].length}{" "}
                                                    {t("management.companies")}
                                                </Typography>
                                            </Box>
                                            <SearchIcon />
                                        </Stack>
                                    </ToggleButton>
                                ))}
                            </StyledToggleButtonGroup>
                            <Typography>
                                <b>{t("management.TotalBill")}</b> US${" "}
                                {parseFloat(
                                    item.line_items.reduce((sum, line) => sum + line.line_amount, 0)
                                ).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                })}
                            </Typography>
                        </Stack>
                        {showDet[index].show &&
                            showDet[index].companies.map((val) => (
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ p: 2, backgroundColor: "#F8F8F9", my: 2, borderRadius: 3 }}
                                    justifyContent="space-between"
                                >
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant="h1" color="primary">
                                            {val.company_name}
                                        </Typography>{" "}
                                        <span>•</span>
                                        <Typography variant="h1">Plan {showDet[index].plan.type}</Typography>{" "}
                                        <Typography>
                                            {plans[showDet[index].plan.id].pages} {t("register.pagesMonthly")}
                                        </Typography>
                                    </Stack>
                                    <Typography fontSize={18}>
                                        <b>
                                            US${" "}
                                            {parseFloat(
                                                item.line_items.reduce((sum, line) => sum + line.line_amount, 0)
                                            ).toLocaleString(todos.amountFormat, {
                                                minimumFractionDigits: 2,
                                            })}
                                        </b>
                                        /{t("plans.month")}
                                    </Typography>
                                </Stack>
                            ))}
                    </Paper>
                ))}
            <Stack direction="row" justifyContent="end" pt={2}>
                <Pagination
                    count={Math.floor(invoices.length / 5)}
                    page={page}
                    onChange={handleChange}
                    showFirstButton
                    showLastButton
                />
            </Stack>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    "& .MuiPopover-paper": {
                        borderRadius: 3,
                        boxShadow: "4px 4px 15px rgba(74, 34, 212, 0.15)",
                    },
                }}
            >
                <MenuList id="split-button-menu" autoFocusItem>
                    {optionsGroupSend.map((data, index) => (
                        <MenuItem
                            key={"convertOption_" + index}
                            onClick={handleMenuItemClickBtnGroup}
                        >
                            <Typography>
                                {data} {index === 0 ? "(.pdf)" : "(.csv)"}
                            </Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>
        </Paper>
    );
};

export default InvoicesList;
