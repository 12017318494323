import { Box, Button, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";

import XeroLogo from "../../../assets/xero.svg";

import SyncIcon from "@mui/icons-material/Sync";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";

import BaseSelect from "../../ui/BaseSelect";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IcomeTableDet from "./IcomeTableDet";
import SimpleBackdrop from "../../ui/SimpleBackdrop";

import conversionService from "../../../services/conversion";
import LightTooltip from "../../ui/LightTooltip";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
    },
    "& p": {
        color: "#131F3E",
    },
});

const IncomePrev = (props) => {
    const { setPreviewInc, setIsReload, conversionData, setOpenPreview, accounts, setAccount } = props;
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const [contact, setContact] = useState("");
    const [reference, setReference] = useState(
        `Reporte Amazon - ${moment().locale(t("language.locale")).format("MMM DD, YYYY")}`
    );
    const [accountsXero, setAccountsXero] = useState([]);
    const [contactsXero, setContactsXero] = useState([]);
    const [xeroTaxRates, setXeroTaxRates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            await conversionService
                .getChartOfAccounts({
                    company_id: todos.companyID,
                    type: 1,
                })
                .then((data) => {
                    setAccountsXero(data.map((item) => ({ value: item.Code, name: item.Name })));
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getContacts({
                    company_id: todos.companyID,
                })
                .then((data) => {
                    setContactsXero(
                        data.map((item) => ({
                            value: item.ContactID,
                            name: item.Name,
                            contact_tax_number: item.contact_tax_number,
                        }))
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionService
                .getTaxRates({
                    company_id: todos.companyID,
                    type: 1,
                })
                .then((data) => {
                    if (data) {
                        setXeroTaxRates(
                            data.map((item) => ({
                                value: item.TaxType,
                                name: `${item.Name} - ${item.EffectiveRate}%`,
                                taxRate: item.EffectiveRate,
                                EffectiveRate: item.EffectiveRate,
                                isZeroTax: item.TaxComponents[0].Name === "No Tax",
                            }))
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            setIsLoading(false);
        })();
    }, [setIsLoading, todos.companyID]);

    const handleChangeContact = (e) => {
        setContact(e.target.value);
    };

    const handleChangeRef = (e) => {
        setReference(e.target.value);
    };

    const handleGoBack = () => {
        setPreviewInc(false);
        setIsReload((prev) => !prev);
    };

    const handleOpenPrev = () => {
        setOpenPreview(true);
    };

    return (
        <Container maxWidth="xl">
            <Stack direction="row" justifyContent="space-between" pt={5}>
                <Button variant="outlined" onClick={handleGoBack}>
                    {t("converter.goBackConver")}
                </Button>
                <Typography gutterBottom>
                    <Button>{t("accounts.needHelp")}</Button>
                </Typography>
            </Stack>
            <Box
                sx={{
                    backgroundColor: "#EFEBFB",
                    px: 3,
                    py: 2,
                    borderRadius: 4,
                    mt: 2,
                }}
            >
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "center", md: "space-between" }}
                    spacing={2}
                >
                    <Box>
                        <Stack direction="row" alignItems="center" spacing={1} pb={1} justifyContent={{ xs: "center" }}>
                            <Typography variant="h3">
                                Se sincronizarán los siguientes reportes de ingreso a Xero
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    height: 20,
                                    width: 20,
                                }}
                                src={XeroLogo}
                            />
                        </Stack>
                        <Typography>
                            <b>1</b> importe en total
                        </Typography>
                    </Box>
                    <Stack direction={{ xs: "column", md: "row" }} alignItems="center" spacing={{ xs: 2, md: 4 }}>
                        <Button
                            variant="outlined"
                            disableElevation
                            endIcon={<DownloadIcon />}
                            sx={{ px: 4.5, py: 1.2 }}
                        >
                            Descargar
                        </Button>
                        <Button variant="contained" disableElevation endIcon={<SyncIcon />} sx={{ px: 4.5, py: 1.2 }}>
                            Sincronizar
                        </Button>
                    </Stack>
                </Stack>
            </Box>
            <Box
                sx={{
                    backgroundColor: "#F8F8F9",
                    px: 3,
                    py: 2,
                    borderRadius: 4,
                    mt: 2,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h2" color="primary" gutterBottom>
                            Resumen de conversión
                        </Typography>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h3">Empresa</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography>{conversionData.formatted_customer_name}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h3">Cuenta bancaria</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography>{conversionData.account_id.account_name}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h3">Nombre del archivo</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Button
                                    onClick={handleOpenPrev}
                                    size="small"
                                    sx={{ fontSize: 14, p: 0 }}
                                    endIcon={<VisibilityIcon />}
                                >
                                    {conversionData.uploaded_file}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h3">Período</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography>01/08/2024 - 31/08/2024</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h3">Moneda del archivo</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography>{conversionData.formatted_currency}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h3">Moneda del banco</Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography>USD</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} md={1.5}></Grid>
                            <Grid item xs={12} md={3.5} mb={1}>
                                <BaseSelect
                                    value={contact}
                                    values={contactsXero}
                                    onChange={handleChangeContact}
                                    label="Contacto"
                                />
                            </Grid>
                            <Grid item xs={12} md={3.5} mb={1}>
                                <CustomTextField
                                    variant="filled"
                                    name="ref"
                                    fullWidth
                                    label="Referencia"
                                    value={reference}
                                    onChange={handleChangeRef}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} md={3.5}></Grid>
                            <Grid item xs={12} md={1.5}>
                                <Typography variant="h3" textAlign="right">
                                    Extraido
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3.5}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ backgroundColor: "#DBF4E5", p: 1.5, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">Total ingresos</Typography>
                                    <Typography>
                                        USD{" "}
                                        {parseFloat(4202.06).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    </Typography>
                                    <LightTooltip title="Los cálculos coinciden con lo extraído del reporte.">
                                        <CheckIcon color="success" />
                                    </LightTooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3.5}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ backgroundColor: "#FFF1DB", p: 1.5, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">Total gastos</Typography>
                                    <Typography>
                                        USD{" "}
                                        {parseFloat(-295.74).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    </Typography>
                                    <ErrorIcon htmlColor="#FFB23F" />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3.5}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ backgroundColor: "#FFF1DB", p: 1.5, borderRadius: 3 }}
                                >
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography variant="h3">Ingreso neto</Typography>
                                        <InfoIcon fontSize="small" sx={{ color: "#4A22D4", fontSize: 15 }} />
                                    </Stack>
                                    <Typography>
                                        USD{" "}
                                        {parseFloat(3906.32).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    </Typography>
                                    <ErrorIcon htmlColor="#FFB23F" />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={1.5}>
                                <Typography variant="h3" textAlign="right">
                                    Calculado
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3.5}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ backgroundColor: "#DBF4E5", p: 1.5, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">Total ingresos</Typography>
                                    <Typography>
                                        USD{" "}
                                        {parseFloat(4202.06).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    </Typography>
                                    <LightTooltip title="Los cálculos coinciden con lo extraído del reporte.">
                                        <CheckIcon color="success" />
                                    </LightTooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3.5}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ backgroundColor: "#FFF1DB", p: 1.5, borderRadius: 3 }}
                                >
                                    <Typography variant="h3">Total gastos</Typography>
                                    <Typography>
                                        USD{" "}
                                        {parseFloat(-275.74).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    </Typography>
                                    <ErrorIcon htmlColor="#FFB23F" />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3.5}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ backgroundColor: "#FFF1DB", p: 1.5, borderRadius: 3 }}
                                >
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography variant="h3">Ingreso neto</Typography>
                                        <InfoIcon fontSize="small" sx={{ color: "#4A22D4", fontSize: 15 }} />
                                    </Stack>
                                    <Typography>
                                        USD{" "}
                                        {parseFloat(3930.32).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    </Typography>
                                    <ErrorIcon htmlColor="#FFB23F" />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <IcomeTableDet
                conversionData={conversionData}
                accountsXero={accountsXero}
                xeroTaxRates={xeroTaxRates}
                accounts={accounts}
                setAccount={setAccount}
            />
            <SimpleBackdrop open={isLoading} />
        </Container>
    );
};

export default IncomePrev;
